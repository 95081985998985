import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ViewTable, formatDateString, dateSortComparator, TextCellExpand } from '../components/Table';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography'; 
import { OverviewIcon } from '../components/LocalIcons';
import { FullPage } from "../components/Sheets";
import { renderBooleanCell } from '../helpers/tableRenders';
import { 
  goToRecord
} from '../helpers/buttonActions';

const Overview = () => {

  const navigate = useNavigate();

  const listTable = 'v_all_data';

  const columns = [
    {
      field: 'open',
      headerName: '',
      width: 90,
      renderCell: (params) => (
        <IconButton 
          aria-label="Open" 
          onClick={() => goToRecord(navigate, params.row.entry_id)}
        >
          <LaunchIcon />
        </IconButton>
      ),
    },    
    { field: 'uuid', 
      headerName: 'UUID', 
      flex: 2, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true,
    },     
    { field: 'entry_id', headerName: 'Id', width: 75 },
    { field: 'entry_name', headerName: 'Name', width: 200,
      renderCell: (cellValues) => {
        return <TextCellExpand value={cellValues.value} />;
      },
    },  
    { field: 'inventory', headerName: 'Inventory', width: 200,
      renderCell: (cellValues) => {
        return <TextCellExpand value={cellValues.value} />;
      },
    },
    {
      field: 'dateStart',
      headerName: 'TPQ',
      width: 150,
      valueGetter: (params) => {
        const matches = params.row.dating && params.row.dating.match(/\[(?:"?)(.*?)(?:"?)\s*,/);
        if (matches && matches[1]) {
          return formatDateString(matches[1]);
        }
        return '';
      },
      sortComparator: dateSortComparator,      
    },
    {
      field: 'dateEnd',
      headerName: 'TAQ',
      width: 150,
      valueGetter: (params) => {
        const matches = params.row.dating && params.row.dating.match(/,\s*(?:"?)(.*?)(?:"?)\)/);
        if (matches && matches[1]) {
          return formatDateString(matches[1]);
        }
        return '';
      },
      sortComparator: dateSortComparator,
    },    
    { field: 'dating_notes', headerName: 'Date notes', width: 150,
      renderCell: (cellValues) => {
        return <TextCellExpand value={cellValues.value} />;
      },
    },
    { field: 'complete', 
      headerName: 'Complete', 
      flex: 1, 
      type: 'boolean', 
      renderCell: renderBooleanCell, 
      width: 50,
      sortable: true
    },
  ];  

  const OverviewTable = () => (
    <ViewTable
      columns={columns}
      listTable={listTable}
    />
  );

  return (
    <>
      <header className="w3-container" style={{paddingTop:"22px"}}>
        <Typography variant="h5" gutterBottom >
          <OverviewIcon /> Overview
        </Typography>
      </header>
      <div className="row">
        <FullPage>
          <OverviewTable />        
        </FullPage>
      </div>
    </>
    );
};

export default Overview;
