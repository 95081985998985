import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LaunchIcon from '@mui/icons-material/Launch';
import { useStore } from '../services/store';
import { 
  InputText,
  SingleSelect, 
} from './InputFields';
import { 
  handleInputChange, 
} from '../helpers/buttonActions';

const DocFields = ({ currData, setCurrData }) => {
  console.log("Render DocumentaryFields")
  const terms = useStore(state => state.terms);

  return (
    <Grid xs={12} >
      <Paper elevation={1}>
        <Grid container spacing={1} padding >
          <Grid xs={12} sm={12} md={6} xl={8}>
            <SingleSelect
              name="doc_type"
              label="Document Type"
              currData={currData}
              setCurrData={setCurrData}
              options={terms?.doc_type}
              optionLabel="term_name" // Pass the property name as a string
              optionValue="uuid" // Pass the property name as a string
            />
          </Grid>         
          <Grid xs={4} sm={6} md={6} xl={4} >   
            <InputText
              name="doc_tm"
              label="Trismegistos"
              value={currData?.doc_tm}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => { window.open(`https://www.trismegistos.org/text/${currData?.doc_tm}`,'_blank');}} edge="end">
                      <LaunchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}           
            />                 
          </Grid>
          <Grid xs={8} sm={3} md={8} xl={6}>
            <SingleSelect
              name="doc_format"
              label="Format"
              currData={currData}
              setCurrData={setCurrData}
              options={terms?.doc_type}
              optionLabel="term_name" // Pass the property name as a string
              optionValue="uuid" // Pass the property name as a string
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} xl={6}>
            <SingleSelect
              name="doc_support"
              label="Support"
              currData={currData}
              setCurrData={setCurrData}
              options={terms?.doc_type}
              optionLabel="term_name" // Pass the property name as a string
              optionValue="uuid" // Pass the property name as a string
            />
          </Grid>
        </Grid> 
      </Paper>  
    </Grid>
  );
};

export default DocFields;