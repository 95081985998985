import React, { useEffect, useState } from 'react';
import { formatISO } from 'date-fns';
import Typography from '@mui/material/Typography'; 
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import { EditTable } from '../components/Table';
import RelationshipGraph from './RelationshipGraph';

const Relationships = ({ currData, parent }) => {    
  console.log("Render Relationships")
  console.log(currData?.uuid)
  const recordLookup = useStore(state => state.recordLookup);
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);  
  const activeActor = useStore(state => state.activeActor);
  const [rowsForDeletion, setRowsForDeletion] = useState([]);
  const [rels, setRels] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (currData){
      const getData = async () => {
        setLoading(true);
        const data = await makeRequest('get', `v_crosslist?or=(parent_uuid.eq.${currData?.uuid},child_uuid.eq.${currData?.uuid})`, {}, {});
        setRels(data);
        setLoading(false);
      };   
      getData();
      console.log("Relationships loaded.")      
    }
  }, [currData,setSnackbarOpen]);

  const columns = [
    {
      field: 'parent_uuid',
      headerName: 'Parent',
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const match = recordLookup.find(option => option.value === params.value);
        return match ? match.label : params.value;
      },      
      renderEditCell: (params) => {
        return (
          <Autocomplete
            style={{ flex: 1 }}
            id='parent_uuid'
            options={recordLookup}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}              
            value={recordLookup.find(option => option.value === params.value) || null}
            onChange={(event, newValue) => {
              params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue?.value || null });
            }}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option) => <li {...props} key={option.value}>{option.label}</li>}               

          />          
        );
      },
    },
    {
      field: 'child_uuid',
      headerName: 'Child',
      flex: 1, 
      editable: true,
      renderCell: (params) => {
        const match = recordLookup.find(option => option.value === params.value);
        return match ? match.label : params.value;
      },      
      renderEditCell: (params) => {
        return (
          <Autocomplete
            style={{ flex: 1 }}
            options={recordLookup}
            getOptionLabel={(option) => option.label || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}              
            value={recordLookup.find(option => option.value === params.value) || null}
            onChange={(event, newValue) => {
              params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue?.value || null });
            }}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option) => <li {...props} key={option.value}>{option.label}</li>}                           
          />          
        );
      },
    },      
    { field: 'relationship', 
      headerName: 'Relationship', 
      flex: 3, 
      type: 'text', 
      editable: true, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'comment', 
      headerName: 'Comment', 
      flex: 4, 
      type: 'text', 
      editable: true, 
      disableColumnMenu: false, 
      sortable: true  
    },      
  ]  
  const handleSave = async () => {
    console.log('Saving Relationships');
    try {      
      const updateData = rels.map(rel => {
        const { parent, child, ...rest } = rel;
        return {
          ...rest,
          updated_at: formatISO(new Date()),
          updated_by: activeActor?.uuid || null
        };
      });

      const updatedRow = await makeRequest(
                                'POST', 
                                `edit_crosslist`,
                                updateData, 
                                "Prefer: resolution=merge-duplicates,return=representation"
                              );
      console.log('Data updated: ', updatedRow);
      if(rowsForDeletion){
        console.log(rowsForDeletion)
        let numberDeletions = rowsForDeletion.length;
        const deletedRows = await makeRequest('DELETE', `edit_crosslist?uuid=eq(any).{${rowsForDeletion}}&limit=${numberDeletions}&order=uuid`,'{}', "Prefer: return=representation");
        console.log(`${numberDeletions} row(s) deleted: ${deletedRows}`);
        setRowsForDeletion([]);
      }  
      setSnackbarData ({
        "actionType": "save",
        "messageType": "success",
        "messageText": "Relationships updated."
      });
    } catch (error) {
      console.log('Error saving data: ', error);     
      setSnackbarData ({
        "actionType": "save",
        "messageType": "error",
        "messageText": "Save failed: \n\n" + error.message + " \n " + error.response?.data.message
      });
    }
    setSnackbarOpen(true)
  };


  if (!currData) {
    return (
       <div className="fill-main" id="rel">
        <Typography variant="h6" component="div" sx={{ textAlign: 'center', padding: 2 }}>
          No entry loaded.
        </Typography>      
      </div>
    );
  }  

  return (
    <>
     <div className="thirtyheight" id="rel">
      <RelationshipGraph rels={rels} currUUID={currData?.uuid || ''} />
     </div>
     <div className="halfheight" id="reltable" style={{overflow:"auto"}}>
      <EditTable columns={columns} rows={rels} setRows={setRels} setRowsForDeletion={setRowsForDeletion} handleSave={handleSave} />
     </div>
    </>
  );
};

export default Relationships;