import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { InputText } from './InputFields';
import { 
  handleInputChange, 
} from '../helpers/buttonActions';

const LitFields = ({ currData, setCurrData }) => {
  console.log("Render LiteraryFields")

  return (
    <Grid xs={12} >
      <Paper elevation={1}>
        <Grid container spacing={1} padding >     
          <Grid xs={12} sm={12} md={6} xl={6} >        
            <InputText
              label="Document"
              name="lit_doc"
              value={currData?.lit_doc}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}         
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} xl={6} >        
            <InputText
              label="Author"
              name="lit_author"
              value={currData?.lit_author}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}                
            />
          </Grid>          
        </Grid> 
      </Paper>  
    </Grid>
  );
};

export default LitFields;