import React, { useState, useRef, useEffect } from 'react';
import { Cite, plugins } from "@citation-js/core";
import "@citation-js/plugin-isbn";
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import { formatISO } from 'date-fns';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useStore } from '../services/store';
import { 
  SingleSelectLocal
} from './InputFields';
import { InventoryFields, MetadataFields, ResourceFields } from './MediaFields';
import { LookupInput, JsonInput } from './BibliographyFields';
import { makeRequest } from '../services/query';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '77%',
  transform: 'translate(-50%, -50%)',
  width: '45%',
  height: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: 4,
};


export const NewMediaModal = ({ type, open, setOpen }) => {
  console.log("Render Images")
  const [newData, setNewData] = useState([]);
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);
  const activeActor = useStore(state => state.activeActor);
  const uuid = useStore(state => state.currUuid);
  const entryId = useStore(state => state.currEntryId);

  //console.log(`Curr ID's: ${uuid} and ${entryId}`)
  const saveMedia = async () => {
    console.log('Saving Media');
    try {
      const updateData = {
        ...newData, 
        parent_type: entryId?.charAt(0) || null, 
        parent: uuid || null, 
        updated_at: formatISO(new Date()), 
        updated_by: activeActor?.uuid || null
      };
      const updatedRow = await makeRequest(
                                'POST', 
                                `edit_media`,
                                updateData, 
                                "Prefer: return=representation"
                              );
      console.log('Data updated: ', updatedRow);     
      setSnackbarData ({
        "actionType": "save",
        "messageType": "success",
        "messageText": "Media saved."
      });
    } catch (error) {
      console.log('Error saving data: ', error);     
      setSnackbarData ({
        "actionType": "save",
        "messageType": "error",
        "messageText": "Save failed: \n\n" + error.message + " \n " + error.response?.data.message
      });
    }
    setSnackbarOpen(true)
    setNewData([]);
    setOpen(false);
  };

  const cancelUpload = () => {
    setNewData([]);
    setOpen(false);
  };  


  return (
      <Modal
        open={open}
        onClose={() => { setOpen(false); }}
        aria-labelledby="new-media-modal"
        aria-describedby="new-media-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="new-media-modal" variant="h6" component="h2">
            Add New Media
          </Typography>
          <Typography id="new-media-modal-description" sx={{ mt: 2 }}>
            Upload a file or add a link to an online resource.
          </Typography>
          <Grid container spacing={2} >
            <InventoryFields currData={newData} setCurrData={setNewData} />
            <MetadataFields currData={newData} setCurrData={setNewData} />
            <ResourceFields currData={newData} setCurrData={setNewData} />
            <Grid xs={6}>
              <Card >
                <CardActionArea>
                  <CardContent onClick={() => { saveMedia() }}>
                    <SaveIcon />
                    <Typography gutterBottom variant="b" component="p">
                      Upload and Save
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid xs={6}>
              <Card >
                <CardActionArea>
                  <CardContent onClick={() => { cancelUpload() }}>
                    <CloseIcon />
                    <Typography gutterBottom variant="b" component="p">
                      Cancel
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>                        
          </Grid>
        </Box>
      </Modal>
  );
};

export const NewBibliographyModal = ({ newData, setNewData, saveData, open, setOpen }) => {
  console.log("Render Bibliography modal")
  const [expanded, setExpanded] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState('');

  const formattedNewBiblio = useRef(null);

  const handleExpandClick = () => {
    setExpanded(!expanded); // Toggle expanded state
  };


  async function fetchBiblio(source) {
    try {
      console.log("source");
      console.log(source);     
      const cslJson = newData.format('bibliography');
      console.log(cslJson)
      setNewData(await Cite.async(source)); // Update state after successful async lookup
    } catch (error) {
      console.log('Error fetching citation:', error);
      const customCslJson = [{
        "id": uuidv4(),
        "type": "book",
        "title": source   
      }]
      setNewData(await Cite.async(customCslJson)); // Update state after successful async lookup
    }     
  }


  const biblioFormats = [
    {'value': 'doi', 'label': 'DOI'},
    {'value': 'isbn', 'label': 'ISBN'},
    {'value': 'pubmed', 'label': 'PubMed'},
    {'value': 'wikidata', 'label': 'Wikidata QID'},
    {'value': 'cslJson', 'label': 'CSL-JSON'},
    {'value': 'bibJson', 'label': 'BibJSON'},
    {'value': 'bibTex', 'label': 'BibTeX/BibLaTeX'},
    {'value': 'ris', 'label': 'RIS'},
    {'value': 'cffZenodo', 'label': 'Citation File Format/Zenodo Deposit JSON'},
    {'value': 'other', 'label': 'Other/Manual'}
  ]
    

  const cancelUpload = () => {
    setNewData(new Cite());
    setOpen(false);
  };  

  useEffect(() => {
    if (formattedNewBiblio.current) {
      const formattedHtml = newData.format('bibliography', { format: 'html', template: 'apa' });
      formattedNewBiblio.current.innerHTML = formattedHtml; // Set innerHTML using ref
    }
  }, [newData]);


  return (
    <Modal
      open={open}
      onClose={() => { setOpen(false); }}
      aria-labelledby="new-biblio-modal"
      aria-describedby="new-biblio-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="new-biblio-modal" variant="h6" component="h2">
          Add New Bibliographic Item
        </Typography>
        <Grid container spacing={2} padding >
{ false &&
          <Grid xs={12}>
            <SingleSelectLocal
              name="format"
              label="Bibliograpy source"
              currData={selectedFormat}
              setCurrData={setSelectedFormat}
              options={biblioFormats}
              optionLabel="label"
              optionValue="value"               
            />
          </Grid>
}
          <Grid xs={12}>
              <LookupInput newData={newData} fetchBiblio={fetchBiblio} />
          </Grid>
          <Grid xs={12}>
            <Typography gutterBottom variant="b" component="p">
              Editable preview (CSL-JSON)
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                size="small" 
                aria-label="show more"
              >
                {expanded ? <ExpandLess /> : <ExpandMore /> }
              </IconButton>              
            </Typography>
            <Collapse in={expanded} timeout="auto" unmountOnExit>     
              <JsonInput newData={newData} fetchBiblio={fetchBiblio} />
            </Collapse>
          </Grid>
          <Grid xs={12}>
            <Typography gutterBottom variant="b" component="p">
              Preview (APA)
            </Typography>          
            <div ref={formattedNewBiblio}></div>
          </Grid>
          <Grid xs={6}>
            <Card >
              <CardActionArea>
                <CardContent onClick={() => { saveData() }}>
                  <SaveIcon />
                  <Typography gutterBottom variant="b" component="p">
                    Save
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={6}>
            <Card >
              <CardActionArea>
                <CardContent onClick={() => { cancelUpload() }}>
                  <CloseIcon />
                  <Typography gutterBottom variant="b" component="p">
                    Cancel
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>                        
        </Grid>
      </Box>
    </Modal>
  );
};
