import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import TransformIcon from '@mui/icons-material/Transform';
import InputAdornment from '@mui/material/InputAdornment';
import { 
  InputText, 
  InputTextMulti, 
  MultiSelect, 
  SingleSelect, 
  CheckInput 
} from './InputFields';

export const DoiInput = ({ fetchBiblio }) => {
  console.log("Render DoiInput")

  const lookup = () => {
    console.log('lookup')
  };

  return (
    <InputText
      name="doi"
      label="Lookup DOI"
      value={''}
      onBlur={(data) => fetchBiblio(data.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => lookup()} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}            
    />           
  );
};

export const LookupInput = ({ newData, fetchBiblio }) => {
  console.log("Render IsbnInput")

  const lookup = () => {
    console.log('lookup')
  };

  return (
      <Grid container spacing={1} padding xs={12} xl={12}> 
        <Grid xs={12}>
          <InputText
            name="identifierLookup"
            label="Lookup identifier"
            value={''}
            onBlur={(data) => fetchBiblio(data.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => lookup()} edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}             
          />           
        </Grid>
        <Grid xs={12}>
          <InputTextMulti
            name="formattedLookup"
            label="Add formatted bibliography"
            rows={8}
            value={''}
            onBlur={(data) => fetchBiblio(data.target.value)}
          />            
        </Grid>                                        
      </Grid>  
  );
};

export const CslInput = ({ newData, fetchBiblio }) => {
  console.log("Render CslInput")
  const [jsonEntry, setJsonEntry] = useState([]);

  const minimalJson = [{
    "id": uuidv4(),
    "type": "book",
    "title": "",
    "author": [{
      "family": "",
      "given": ""
    }],
    "publisher": ""
  }]

  const lookup = () => {
    console.log('lookup')
  };

  useEffect(() => {
    const cslData = newData.format('data');
    console.log(cslData)
    if ( cslData !== []) {
      setJsonEntry(cslData);
    } else {
      setJsonEntry(minimalJson);
    }
  }, [newData]);

  return (
      <Grid container spacing={1} padding xs={12} xl={12}> 
        <Grid xs={12}>
          <InputTextMulti
            name="cslJson"
            label="CSL-JSON"
            rows={8}
            value={jsonEntry}
            onBlur={(data) => fetchBiblio(data.target.value)}
          />            
        </Grid>
        <Grid xs={4}>
          <Card >
            <CardActionArea>
              <CardContent onClick={() => { lookup() }}>
                <TransformIcon />
                <Typography gutterBottom variant="b" component="p">
                  Render data
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>                                         
      </Grid>  
  );
};

export const JsonInput = ({ newData, fetchBiblio }) => {
  console.log("Render JsonInput")
  const [jsonEntry, setJsonEntry] = useState([]);

  const minimalJson = [{
    "id": uuidv4(),
    "type": "book",
    "title": "",
    "author": [{
      "family": "",
      "given": ""
    }],
    "publisher": "",
    "issued": {
      "date-parts": [[ 2000 ]]
    }    
  }]

  useEffect(() => {
    const cslData = newData.format('data');
    console.log(cslData)
    if ( cslData !== '[]') {
      setJsonEntry(cslData);
    } else {
      setJsonEntry(JSON.stringify(minimalJson, null, 4));
    }
  }, [newData]);

  return (
    <Grid container spacing={1} padding xs={12} xl={12}> 
      <Grid xs={12}>    
        <InputTextMulti
          name="json"
          label="CSL-JSON formatted record"
          rows={14}
          value={jsonEntry}
          onBlur={(data) => fetchBiblio(data.target.value)}
        /> 
      </Grid>                                         
    </Grid>  
  );
};

  