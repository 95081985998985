import React from "react";
import SvgIcon from '@mui/material/SvgIcon';
import { mdiScript } from '@mdi/js';
import { mdiBookshelf } from '@mdi/js';
import { mdiBank } from '@mdi/js';
import { mdiEye } from '@mdi/js';
import { mdiSetAll } from '@mdi/js';
import { mdiSitemap } from '@mdi/js';
import { mdiPuzzle } from '@mdi/js';
import { mdiImageMultiple } from '@mdi/js';
import { mdiMap } from '@mdi/js';
import { mdiInformationVariant } from '@mdi/js';
import { mdiCog } from '@mdi/js';

export function OverviewIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.2}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiSetAll}
        />
      </svg>
    </SvgIcon>
  );
}

export function DocumentaryIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.5}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiScript}
        />
      </svg>
    </SvgIcon>
  );
}

export function LiteraryIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.2}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiBookshelf}
        />
      </svg>
    </SvgIcon>
  );
}

export function ArchIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.3}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiBank}
        />
      </svg>
    </SvgIcon>
  );
}

export function VisualIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.2}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiEye}
        />
      </svg>
    </SvgIcon>
  );
}

export function RelationshipIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.2}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiSitemap}
        />
      </svg>
    </SvgIcon>
  );
}

export function TermIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.2}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiPuzzle}
        />
      </svg>
    </SvgIcon>
  );
}

export function MediaIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.2}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiImageMultiple}
        />
      </svg>
    </SvgIcon>
  );
}

export function MapIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.2}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiMap}
        />
      </svg>
    </SvgIcon>
  );
}

export function AboutIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.2}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiInformationVariant}
        />
      </svg>
    </SvgIcon>
  );
}

export function SettingsIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={0.2}
        stroke="currentColor"
        style={{verticalAlign:"middle"}}
      >
        <path
          //strokeLinecap="round"
          //strokeLinejoin="round"
          d={mdiCog}
        />
      </svg>
    </SvgIcon>
  );
}