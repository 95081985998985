import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { useStore } from '../services/store';
import useCrudActions from '../components/CrudActions';
import CrudButtons from '../components/DesktopCrudButtons';
import Typography from '@mui/material/Typography'; 
import { InventoryFields, MetadataFields, ParentFields, ResourceFields } from '../components/MediaFields';
import MediaViewer from '../components/MediaViewer';
import TableDrawer from '../components/TableDrawer';
import Audit from '../components/MimaAudit';
import { MediaIcon } from '../components/LocalIcons';
import { Threed } from '../components/Mima3d';
import {HalfPage} from "../components/Sheets";
import {ReadMap} from "../components/MimaMap";
import { makeRequest } from '../services/query';


const NavRight = () => {
  function scrollto(id, instant){
    if(instant && navigator.userAgent.match(/chrome|chromium|crios|edg/i)){
      document.getElementById(id).scrollIntoView();
    } else {
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
    };
  }
  return (
    <div className="w3-center w3-xlarge w3-text-white w3-display-right w3-dark-grey w3-round-xxlarge w3-margin rightMenu">
      <span className="fa fa-link w3-transparent w3-hover-shadow" onClick={() => scrollto('viewer')} style={{cursor:"pointer"}} />
      <span className="fa fa-map-o fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('map')} style={{cursor:"pointer"}} />
      <span className="fa fa-eye fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('audit')} style={{cursor:"pointer"}} />      
    </div>
    );
};

const LeftHalf = ({ currData, setCurrData }) => {
  if(!currData){
    return (
      <div className="fill-main" id="form">
        <CrudButtons rowSelected={false} />
      </div>
    )    
  };
  return (
    <>
      <div className="fill-most" >
        <div className="fill-most" id="form" >
          <Grid container spacing={2} >      
            <InventoryFields currData={currData} setCurrData={setCurrData} />
            <MetadataFields currData={currData} setCurrData={setCurrData} />
            <ParentFields currData={currData} setCurrData={setCurrData} />
            <ResourceFields currData={currData} setCurrData={setCurrData} />
          </Grid>
        </div>     
      </div>           
      <Grid container spacing={2} padding  > 
        <CrudButtons rowSelected={true} />
      </Grid>
    </>
    )

};


const Media = () => {
  console.log("Render Media")

  let { id } = useParams(); // Grabs the entryID from the URL  

  const setTableOpen = useStore(state => state.setTableOpen);
  const currData = useStore(state => state.currMedia);
  const setCurrData = useStore(state => state.setCurrMedia);

  const apiTable = 'edit_media'
  const navigate = useNavigate();


  useEffect(() => {
    if (id){
      const loadData = async () => {
        try {
          const getData = await makeRequest('get', `${apiTable}?uuid=eq.${id}`, {}, {});
          setCurrData(getData[0])
        } catch (error) {
          console.error('Could not load data for record:', error);
        }
      };
      loadData();
    } else if(currData){
      navigate(currData.entry_id)
    } else {
      setTableOpen(true)
    }
  }, [id]);

  useCrudActions({
    setSelectedRowData: setCurrData,
    selectedRowData: currData,
    viewTable: apiTable,  
    editTable: apiTable
  });

  const listColumns = [
    { field: 'media_id', 
      headerName: 'Id', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'media_type', 
      headerName: 'Type', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },    
    { field: 'parent_type', 
      headerName: 'Parent Type', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'parent', 
      headerName: 'Parent', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },  
    { field: 'link', 
      headerName: 'Link', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },  
  ]

  return (
    <>
      <header className="w3-container" >
        <Typography variant="h5" gutterBottom >
          <MediaIcon /> Media
        </Typography>
        <a href="#" className="w3-bar-item w3-button w3-padding w3-right" type="button" onClick={() => setTableOpen(true)}>
          <i className="fa fa-list fa-fw"></i>  View list
        </a>
      </header>
      <div className="row">
        <HalfPage>
          <LeftHalf currData={currData} setCurrData={setCurrData} />

        </HalfPage>
        <HalfPage>
          <div className="fill-main" id="viewer">
          { currData?.media_type === '06483fe6-591c-4c49-81fd-c820fa280689' ?
            <Threed 
              currData={currData}
              setCurrData={setCurrData}
              parent={'media'}
            /> :          
            <MediaViewer 
              currData={currData}
              setCurrData={setCurrData}
              parent={'media'}
            /> 
          }
          </div>
          <ReadMap 
            currData={currData}
            setCurrData={setCurrData}
            parent={'media'}
          />          
          <Audit 
            currData={currData}
            setCurrData={setCurrData}
            parent={'media'}
          />          
          <NavRight />
        </HalfPage>        
      </div>
      <TableDrawer 
        columns={listColumns}
        listTable={'list_media'}
      />      
    </>
    );
};

export default Media;
