import React from "react";


export const Threed = () => {

  const src = "/resources/3dviewer?model=gargo.nxz"

  return (
    <div id="3d" className="fill-main" style={{width:"100%",overflow:"hidden"}} >
      <iframe src={src} frameBorder="0" width="100%" height="100%"></iframe>    
    </div>
  );
};






