import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { 
  InputText,
  SingleSelect, 
} from './InputFields';
import { useStore } from '../services/store';
import { 
  handleInputChange, 
} from '../helpers/buttonActions';

const VisFields = ({ currData, setCurrData }) => {
  console.log("Render VisualFields")
  const terms = useStore(state => state.terms);
  console.log(terms);

  return (
    <Grid xs={12} >
      <Paper elevation={1}>
        <Grid container spacing={1} padding >
          <Grid xs={12} sm={12} md={6} xl={6}>
            <SingleSelect
              name="vis_type"
              label="Visual Type"
              currData={currData}
              setCurrData={setCurrData}
              options={terms?.doc_type}
              optionLabel="term_name" 
              optionValue="uuid" 
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} xl={6}>
            <SingleSelect
              name="vis_material"
              label="Material"
              currData={currData}
              setCurrData={setCurrData}
              options={terms?.doc_type}
              optionLabel="term_name" 
              optionValue="uuid" 
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} xl={6} >
            <InputText
              label="Provenance"
              name="vis_provenance"
              value={currData?.vis_provenance}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}                
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} xl={6} >
            <InputText
              label="Size"
              name="vis_size"
              value={currData?.vis_size}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}                
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} xl={12} >
            <InputText
              label="Caption"
              name="vis_caption"
              value={currData?.vis_caption}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}                
            />              
          </Grid>

        </Grid> 
      </Paper>  
    </Grid>
  );
};

export default VisFields;