import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { InputTextMulti, CheckInput } from './InputFields';
import { 
  handleInputChange,
} from '../helpers/buttonActions';

const ArchFields = ({ currData, setCurrData }) => {
  console.log("Render ArchaeologicalFields")

  return (
    <Grid xs={12} >
      <Paper elevation={1}>
        <Grid container spacing={1} padding >
          <Grid xs={12} >
            <InputTextMulti
              name="arch_excavations"
              label="Excavations"
              value={currData?.arch_excavations}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}
              rows={3}
            />   
          </Grid>
          <Grid xs={3} >
            <CheckInput
              name="arch_visited"
              label="Visited"
              currData={currData}
              setCurrData={setCurrData}
            />
          </Grid>          
        </Grid> 
      </Paper>  
    </Grid>
  );
};

export default ArchFields;