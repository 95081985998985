import React, { useState, useCallback  } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from '../services/store';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';


const UploadForm = ({ currData, setCurrData }) => {
  const [loading, setLoading] = useState(false);
  const mediaTypes = useStore(state => state.mediaTypes);  
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);  

  const onDrop = useCallback(acceptedFiles => {
    setLoading(true);
    console.log('Files for upload:')
    console.log(acceptedFiles);
    // Take the first file if multiple files are dropped (if allowing multiple files, then iterate over them)
    const file = acceptedFiles[0];
    let newUuid = currData?.uuid
    let filetype = currData?.media_type; 
    // Map media_type on mediaTypes to get label for uuid.

    const formData = new FormData();
    formData.append('file', file);

    // Add additional metadata if needed
    if(!newUuid) {
      newUuid = uuidv4();
    } 

    formData.append('uuid', newUuid);
    formData.append('type', filetype);
    formData.append('fileName', file.name);
      
    // Post the form data to the server endpoint
    axios.post('https://historyofincarceration.com/upload/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      console.log('File uploaded successfully');
      if (response?.data?.file?.path){
        setCurrData({ 
          ...currData,
          'uuid': newUuid,
          'url': `/upload${response.data.file.path}`,
          'filename': response.data.file.originalname
        })
      }
      setSnackbarData ({
        "actionType": "upload",
        "messageType": "success",
        "messageText": `${response.data.message} ${response.data.file.originalname} --> ${response.data.file.filename}`
      });      
      setLoading(false);
      setSnackbarOpen(true)  
    })
    .catch(error => {
      console.error('Error uploading file', error);
      setSnackbarData ({
        "actionType": "upload",
        "messageType": "error",
        "messageText": `Upload failed: ${error}`
      });
      setLoading(false);
      setSnackbarOpen(true)  
    });
  }, []);

  // Instantiate the hook
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
      accept: {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
        "application/pdf": [".pdf"]
        // Add nxz and nxs file types
      },       
  });

  return (
   <> 
    {
      loading ? (
        <CircularProgress />
      ): (
        <div {...getRootProps()} style={{border: '2px dashed #000', padding: '20px', cursor: 'pointer'}}>
          <input {...getInputProps()} />
          <p>Drag, or click to select a file</p>
        </div>
      )
    }
  </>
  );
};

export default UploadForm;
