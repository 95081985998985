import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {ViewTable, JsonCellExpand } from '../components/Table';
import { useStore } from '../services/store';
import Grid from '@mui/material/Unstable_Grid2';
import { makeRequest } from '../services/query';

function isUUID(str) {
  const regexp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return regexp.test(str);
}




const Audit = ({ parent }) => {
  console.log("Render Audit")

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const uuid = useStore(state => state.currUuid);

  var apiTable = `v_audit?row_uuid=eq.`;
  apiTable += isUUID(id) ? id : uuid;

  const idCol = 'event_id'

//  useEffect(() => {
//    if (id){
//      const loadData = async () => {
//        try {
//          const getAllData = await makeRequest('get', `${apiTable}?row_uuid=eq.${id}`, {}, {});
//          setAllData(getAllData[0])
//        } catch (error) {
//          console.error('Could not load data for record:', error);
//        }
//      };
//      loadData();
//    }
//  }, [id]);

  const columns = [
    { field: 'event_id', 
      headerName: 'ID', 
      flex: 0.5, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true,
    },      
    { field: 'changed_fields', 
      headerName: 'Old',
      renderCell: (cellValues) => {
        return <JsonCellExpand value={cellValues.value} />;
      },      
//      renderCell: (params) => (
//      JSON.stringify(params.row.changed_fields, 0, 2)
//      ),      
      flex: 2, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'row_data', 
      headerName: 'New',
      renderCell: (params) => {
        return <JsonCellExpand value={params.row.row_data} />;
      },       
      flex: 2, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },        
    { field: 'action', 
      headerName: 'Action', 
      flex: 0.5, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'action_tstamp_tx', 
      headerName: 'Timestamp', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'actor', 
      headerName: 'Actor', 
      flex: 0.5, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },     
  ]  
  return (
    <>
      <div className="thirtyheight" id="audit">
        <ViewTable
          columns={columns}
          listTable={apiTable}
          idCol={idCol}

        />
      </div>
      <div className="halfheight">
        <Grid container spacing={2} paddingTop > 
          
        </Grid>
      </div>
    </>
    );
};

export default Audit;
