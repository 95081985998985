import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import UploadForm from './FileUpload';
import { useStore } from '../services/store';
import { 
  InputText, 
  InputTextMulti, 
  SingleSelect, 
  CheckInput 
} from './InputFields';
import { 
  handleInputChange,
  goToRecord
} from '../helpers/buttonActions';

export const InventoryFields = ({ currData, setCurrData }) => {
  console.log("Render InventoryFields")
  const mediaTypes = useStore(state => state.mediaTypes);
  console.log(mediaTypes)

  return (
    <Grid xs={12} >
      <Grid container spacing={1} padding xs={12} xl={12}> 
        <Grid xs={4} sm={3} md={3}>
          <InputText
            name="media_id"
            label="Id"
            value={currData?.media_id}
            onBlur={(data) => handleInputChange(data, currData, setCurrData)}
          />           
        </Grid>
        <Grid xs={8} sm={9} md={9}>
          <InputText
            name="filename"
            label="Filename"
            value={currData?.filename}
            onBlur={(data) => handleInputChange(data, currData, setCurrData)}
          />
        </Grid>
        <Grid xs={12}>
            <SingleSelect
              name="media_type"
              label="Type"
              currData={currData}
              setCurrData={setCurrData}
              options={mediaTypes}
              optionLabel="label"
              optionValue="uuid" 
            />
        </Grid>                                         
      </Grid>  
    </Grid>   
  );
};

export const MetadataFields = ({ currData, setCurrData }) => {
  console.log("Render MetadataFields")
  const actors = useStore(state => state.actors);

  return (
    <Grid xs={12} >      
      <Paper elevation={1} sx={{ width: '100%' }}>
        <Grid container spacing={1} padding xs={12} xl={12}>
          <Grid xs={12} >        
            <Typography variant="p" >
              Metadata
            </Typography> 
          </Grid>   
          <Grid xs={12} >
            <InputTextMulti
              name="description"
              label="Description"
              value={currData?.description}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}
              rows={3}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <SingleSelect
              name="creator"
              label="Creator"
              currData={currData}
              setCurrData={setCurrData}
              options={actors}
              optionLabel="name"
              optionValue="uuid"
            />     
          </Grid>
          <Grid xs={12} sm={6} >
            <InputText
              name="captured_at"
              label="Capture Time"
              value={currData?.captured_at}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}
            />
          </Grid>                           
          <Grid xs={12} >
            <InputText
              name="copyright"
              label="Copyright Information"
              value={currData?.copyright}
              onBlur={(data) => handleInputChange(data, currData, setCurrData)}
            />
          </Grid>                         
        </Grid>
      </Paper>
    </Grid>       
  );
};

export const ParentFields = ({ currData, setCurrData }) => {
  console.log("Render ParentFields")
  const recordLookup = useStore(state => state.recordLookup);
  const [parent, setParent] = useState(null);  

  const navigate = useNavigate();

  const parent_types = [
    {
      value: 'A',
      label: 'Archaeological'
    },{
      value: 'D',
      label: 'Documentary'
    },{
      value: 'L',
      label: 'Literary'
    },{
      value: 'V',
      label: 'Visual'            
    }
  ]

  useEffect(() => {
    setParent(
      currData ? recordLookup.find(option => option.value === currData.parent) || null : null
    );
  }, [currData]);


  return (
    <Grid xs={12} >      
      <Paper elevation={1} sx={{ width: '100%' }}>
        <Grid container spacing={1} padding xs={12} xl={12}>
          <Grid xs={12} >        
            <Typography variant="p" >
              Parent data
            </Typography> 
          </Grid>
          <Grid xs={12} sm={6}>
            <SingleSelect
              name="parent_type"
              label="Parent Type"
              currData={currData}
              setCurrData={setCurrData}
              options={parent_types}
              optionLabel="label"
              optionValue="value"
            />    
          </Grid>
          <Grid xs={12} sm={6}>
            <SingleSelect
              name="parent"
              label="Parent record"
              currData={currData}
              setCurrData={setCurrData}
              options={recordLookup}
              optionLabel="label"
              optionValue="value"
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Open"
                    onClick={() => parent && goToRecord(navigate, parent.label)}
                    edge="end"
                  >
                    <LaunchIcon />
                  </IconButton>
                </InputAdornment>
              )}            
            />           
          </Grid>
        </Grid>
      </Paper>
    </Grid>                           
  );
};

export const ResourceFields = ({ currData, setCurrData }) => {
  console.log("Render ResourceFields")

  return (
    <Grid xs={12} >      
      <Paper elevation={1} sx={{ width: '100%' }}>
        <Grid container spacing={1} padding xs={12} xl={12}>
          <Grid xs={12} >        
            <Typography variant="p" >
              Resource Data
            </Typography> 
          </Grid>   
          <Grid xs={3} >
            <CheckInput
              name="link"
              label="External media"
              currData={currData}
              setCurrData={setCurrData}
            />
          </Grid>
          { currData?.link &&
            <Grid xs={9} >
              <InputText
                name="url"
                label="URL"
                value={currData?.url}
                onBlur={(data) => handleInputChange(data, currData, setCurrData)}
              />
            </Grid> 
          } 
          { !currData?.link &&
            <Grid xs={12} >
              <Typography variant="p" >
                Upload file
              </Typography>
              <UploadForm currData={currData} setCurrData={setCurrData} />
            </Grid> 
          }           
        </Grid>
      </Paper>
    </Grid>        
  );
};