import React from 'react';


export const HalfPage = ({ children }) => {
  return (
    <div className="w3-col m6 w3-card-4 w3-light-grey fill-main" style={{overflow: "hidden"}}>
      {children}
    </div>
  );
};

export const TwoThirdsPage = ({ children }) => {
  return (  
  <div className="w3-col m8 w3-card-4 w3-light-grey fill-main" style={{overflow: "hidden"}}>
    {children}
  </div>
  );
};

export const OneThirdsPage = ({ children }) => {
  return (  
  <div className="w3-col m4 w3-card-4 w3-light-grey fill-main" style={{overflow: "hidden"}}>
    {children}
  </div>
  );  
};

export const FullPage = ({ children }) => {
  return (  
  <div className="w3-col m12 w3-card-4 w3-light-grey fill-main" style={{overflow: "hidden"}}>
    {children}
  </div>
  );
};