import React from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';

// Custom cell renderer
export function renderBooleanCell(params) {
  return params.value ? (
    <CheckCircle style={{ color: green[500] }} />
  ) : (
    <Cancel style={{ color: red[500] }} />
  );
}
