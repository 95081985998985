import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography'; 
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useStore } from '../services/store';
import { makeRequest } from '../services/query';
import { NewMediaModal } from './MimaModals';
import { 
  goToRecord  
} from '../helpers/buttonActions';

const ResourceCard = ({ item, index, navigate, type }) => {
  const [expanded, setExpanded] = useState(false);

  const handleEdit = () => {
    console.log('edit')
  };

  const handleDelete = () => {
    console.log('delete')
  };

  const handleExpandClick = () => {
    setExpanded(!expanded); // Toggle expanded state
  };

  return (
    <Grid xs={12} sm={expanded ? 12 : 6} md={expanded ? 12 : 4}>
      <Card variant="outlined" sx={{ marginBottom: 2, width: '100%', gridRowEnd: expanded ? 'span 2' : undefined }}>
        <CardContent>
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            <Link href={item.url} target="_blank" rel="noopener noreferrer">
              {item.filename ? item.filename : item.url ? item.url : item.uuid}
            </Link>
          </Typography>
        </CardContent>
        {type === 'image' && (
          <CardMedia
            component="img"
            image={`https://historyofincarceration.com/upload/redirect?url=${item.url}`}
            alt="Image preview"
            sx={{ objectFit: 'cover' }}
          />
        )}
        {type === 'document' && (
          <iframe
            src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(item.url)}`}
            width="100%"
            height={expanded ? "500" : "auto"}
            frameBorder="0"
            title={`PDF Preview - ${index}`}
          ></iframe>
        )}
        {type === 'link' && (
          <iframe
            src={`https://historyofincarceration.com/upload/redirect?url=${item.url}`}
            width="100%"
            height={expanded ? "500" : "auto"}
            frameBorder="0"
            title={`Web Preview - ${index}`}
          ></iframe>
        )}
        {type === 'threed' && (
          <iframe
            src={item.filename 
              ? `/resources/3dviewer?model=${encodeURIComponent(item.filename)}`
              : item.url 
                ? item.url
                : `/resources/3dviewer?model=${encodeURIComponent(item.uuid)}`}
            width="100%"
            height={expanded ? "500" : "auto"}
            frameBorder="0"
            title={`3dhop - ${index}`}
          ></iframe>
        )}                        
        <CardActions>
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            size="small" 
            aria-label="show more"
          >
            {expanded ? <ExpandLess /> : <ExpandMore /> }
          </IconButton>
          <IconButton aria-label="edit" size="small" onClick={() => handleEdit()}>
            <Edit />
          </IconButton>
          <IconButton aria-label="delete" size="small" onClick={() => handleDelete()}>
            <Delete />
          </IconButton>
          <IconButton
            aria-label="Open" size="small" onClick={() => goToRecord(navigate, `/Media/${item.uuid}`)}>
            <LaunchIcon />
          </IconButton>             
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {item.description &&
            <Typography variant="body1" sx={{ padding: 1 }}>
                <Typography component="span" fontStyle="italic">Description:</Typography> {item.description}
            </Typography>
          }
          {item.copyright &&
            <Typography variant="body1" sx={{ padding: 1 }}>
                <Typography component="span" fontStyle="italic">Copyright:</Typography> {item.copyright}
            </Typography>
          }

          {/* Potentially other content types could be handled here */}
        </Collapse>
      </Card>
    </Grid>
  );
};

export const Images = ({ type }) => {
  console.log("Render Images")
  const uuid = useStore(state => state.currUuid);
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const type_uuids = '{f43e4fd9-10b7-4432-b947-19273be90815,2ed3c20f-e7b6-4046-8fd1-9292c1604b0b,dc3384a9-ec82-4c31-92dd-9070ab462886,abd54336-5559-43d1-9a09-3c51c4d01c77}'

  useEffect(() => {
    if (uuid){
      const getData = async () => {
        setLoading(true);
        const data = await makeRequest('get', `edit_media?parent=eq.${uuid}&media_type=eq(any).${type_uuids}`, {}, {});
        setMedia(data);
        setLoading(false);
        console.log(data)
      };   
      getData();
      console.log("Media loaded.")      
    }
  }, [uuid]);

  if (!media) {
    return (
      <div className="fill-main" id="images">
        <Typography variant="h6" component="div" sx={{ textAlign: 'center', padding: 2 }}>
          No media found.
        </Typography>      
      </div>
    );
  }  

  return (
    <div className="fill-main" id="images">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography variant="h6" component="p"  sx={{ padding: 1 }}>
            Images
          </Typography>        
            {/* Map through the links array to generate the list of Paper Cards */}
            <Grid container spacing={2} >
              {media?.map((item, index) => (
                  <ResourceCard
                    item={item}
                    navigate={navigate}
                    index={index}
                    key={index}
                    type={'image'} />
              ))}
            </Grid>
        </Grid>
        <Grid xs={12}>
          <Card >
            <CardActionArea>
              <CardContent onClick={() => { setOpen(true); }}>
                <AddIcon />
                <Typography gutterBottom variant="b" component="p">
                  Add New Image
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <NewMediaModal
        open={open}
        setOpen={setOpen}
        type={'f43e4fd9-10b7-4432-b947-19273be90815'}
      />    
    </div>
  );
};

export const Documents = ({ type }) => {
  console.log("Render Documents")
  const uuid = useStore(state => state.currUuid);
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const type_uuids = '{54bf7600-e772-4775-b729-79f92821e74a,55ce48b3-18eb-4d98-a11a-8c8c7468a905}'

  useEffect(() => {
    if (uuid){
      const getData = async () => {
        setLoading(true);
        const data = await makeRequest('get', `edit_media?parent=eq.${uuid}&media_type=eq(any).${type_uuids}`, {}, {});
        setMedia(data);
        setLoading(false);
        console.log(data)
      };   
      getData();
      console.log("Docs loaded.")      
    }
  }, [uuid]);


  

  if (!media) {
    return (
      <div className="fill-main" id="documents">
        <Typography variant="h6" component="div" sx={{ textAlign: 'center', padding: 2 }}>
          No media found.
        </Typography>      
      </div>
    );
  }  

  return (
    <div className="fill-main" id="documents">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography variant="h6" component="p"  sx={{ padding: 1 }}>
            Documents
          </Typography>        
            {/* Map through the links array to generate the list of Paper Cards */}
            <Grid container spacing={2} >
              {media?.map((item, index) => (
                  <ResourceCard
                    item={item}
                    navigate={navigate}
                    index={index}
                    key={index}
                    type={'document'} />
              ))}
            </Grid>
        </Grid>
        <Grid xs={12}>
          <Card >
            <CardActionArea>
              <CardContent onClick={() => { setOpen(true); }}>
                <AddIcon />
                <Typography gutterBottom variant="b" component="p">
                  Add New Document
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <NewMediaModal
        open={open}
        setOpen={setOpen}
        type={'54bf7600-e772-4775-b729-79f92821e74a'}
      />    
    </div>
  );
};

export const Links = ({ type }) => {
  console.log("Render Links")
  const uuid = useStore(state => state.currUuid);
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const type_uuids = '{08c7962d-283f-4ff7-91fe-23e28c08d344}'

  useEffect(() => {
    if (uuid){
      const getData = async () => {
        setLoading(true);
        const data = await makeRequest('get', `edit_media?parent=eq.${uuid}&media_type=eq(any).${type_uuids}`, {}, {});
        setMedia(data);
        setLoading(false);
        console.log(data)
      };   
      getData();
      console.log("Links loaded.")      
    }
  }, [uuid]);


  if (!media) {
    return (
      <div className="fill-main" id="links">
        <Typography variant="h6" component="div" sx={{ textAlign: 'center', padding: 2 }}>
          No media found.
        </Typography>      
      </div>
    );
  }  

  return (
    <div className="fill-main" id="links">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography variant="h6" component="p"  sx={{ padding: 1 }}>
            Web Resources
          </Typography>        
            {/* Map through the links array to generate the list of Paper Cards */}
            <Grid container spacing={2} >
              {media?.map((item, index) => (
                  <ResourceCard
                    item={item}
                    navigate={navigate}
                    index={index}
                    key={index}
                    type={'link'} />
              ))}
            </Grid>
        </Grid>
        <Grid xs={12}>
          <Card >
            <CardActionArea>
              <CardContent onClick={() => { setOpen(true); }}>
                <AddIcon />
                <Typography gutterBottom variant="b" component="p">
                  Add New Resource
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <NewMediaModal
        open={open}
        setOpen={setOpen}
        type={'08c7962d-283f-4ff7-91fe-23e28c08d344'}
      />    
    </div>
  );
};

export const Threed = ({ type }) => {
  console.log("Render 3d models")
  const uuid = useStore(state => state.currUuid);
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const type_uuids = '{}' //add uuid of 3d model type

  useEffect(() => {
    if (uuid){
      const getData = async () => {
        setLoading(true);
        const data = await makeRequest('get', `edit_media?parent=eq.${uuid}&media_type=eq(any).${type_uuids}`, {}, {});
        setMedia(data);
        setLoading(false);
        console.log(data)
      };   
      getData();
      console.log("Models loaded.")      
    }
  }, [uuid]);

  if (!media) {
    return (
      <div className="fill-main" id="3d">
        <Typography variant="h6" component="div" sx={{ textAlign: 'center', padding: 2 }}>
          No models found.
        </Typography>      
      </div>
    );
  }  

  return (
    <div className="fill-main" id="3d" style={{width:"100%",overflow:"hidden"}}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography variant="h6" component="p"  sx={{ padding: 1 }}>
            3d Models
          </Typography>        
            {/* Map through the links array to generate the list of Paper Cards */}
            <Grid container spacing={2} >
              {media?.map((item, index) => (
                  <ResourceCard
                    item={item}
                    navigate={navigate}
                    index={index}
                    key={index}
                    type={'threed'} />
              ))}
            </Grid>
        </Grid>
        <Grid xs={12}>
          <Card >
            <CardActionArea>
              <CardContent onClick={() => { setOpen(true); }}>
                <AddIcon />
                <Typography gutterBottom variant="b" component="p">
                  Add New Model
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <NewMediaModal
        open={open}
        setOpen={setOpen}
        type={'54bf7600-e772-4775-b729-79f92821e74a'}
        //change to uuid for 3d model type
      />    
    </div>
  );
};