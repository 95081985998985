import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from '@mui/material/CardActionArea';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useStore} from '../services/store';


const ConfirmationDialog = ({ open, onClose, onConfirm, title, description, confirmButtonText, confirmButtonColor }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={onConfirm} color={confirmButtonColor} autoFocus>{confirmButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

const CrudButtons = ({ rowSelected }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});
  const buttonAction = useStore(state => state.buttonAction);
  const setButtonAction = useStore(state => state.setButtonAction);

  const handleOpenDialog = (type) => {
    const configs = {
      new: {
        title: "Add new",
        description: "Add new row?",
        confirmButtonText: "Add new",
        confirmButtonColor: "primary",
        onConfirm: () => updateButtonAction("new", true)
      },
      save: {
        title: "Save",
        description: "Changes will be saved to database.",
        confirmButtonText: "Save",
        confirmButtonColor: "primary",
        onConfirm: () => updateButtonAction("save", true)
      },      
      revert: {
        title: "Revert Changes",
        description: "All unsaved changes will be lost.",
        confirmButtonText: "Revert",
        confirmButtonColor: "primary",
        onConfirm: () => updateButtonAction("revert", true)
      },
      delete: {
        title: "Delete row",
        description: "This will delete the object and all geometries",
        confirmButtonText: "Delete",
        confirmButtonColor: "error",
        onConfirm: () => updateButtonAction("delete", true)
      }
      // Add additional button configurations here if needed
    };

    setOpenDialog(true);
    setDialogConfig(configs[type] || {});
  };

  const updateButtonAction = (actionName, value) => {
    setOpenDialog(false);
    const updatedAction = { ...buttonAction, [actionName]: value };
    setButtonAction(updatedAction);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if(!rowSelected) {
    return (
        <Grid container spacing={1} padding sx={{ paddingTop: '22px' }}>
          <Grid xs={12} >
            <Card >
              <CardActionArea>
                <CardContent onClick={() => { updateButtonAction("new", true); }}>
                  <AddIcon />
                  <Typography gutterBottom variant="b" component="p">
                    New
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card> 
          </Grid>     
          <Grid xs={12} >
            <Typography gutterBottom variant="italic" component="p">
              No record selected. Add new or browse through existing.
            </Typography>        
          </Grid>
        </Grid>      
      )

  }
  return (
    <>
      <Grid xs={3} sm={3} md={3} lg={2} >
        <Card >
          <CardActionArea>
            <CardContent 
              onClick={() => { handleOpenDialog('revert'); }} >
              <SettingsBackupRestoreIcon  />
              <Typography gutterBottom component="p">
                Revert
              </Typography>                  
            </CardContent>
          </CardActionArea>
        </Card>        
      </Grid>
      <Grid xs={3} sm={3} md={3} lg={4}>
        <Card >
          <CardActionArea>
            <CardContent onClick={() => { updateButtonAction("new", true); }}>
              <AddIcon />
              <Typography gutterBottom variant="b" component="p">
                New
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card> 
      </Grid>
      <Grid xs={3} sm={3} md={3} lg={4}>
        <Card >
          <CardActionArea>
            <CardContent onClick={() => { updateButtonAction("save", true); }}>
              <SaveIcon  />
              <Typography gutterBottom variant="i" component="p">
                Save
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card> 
      </Grid>        
      <Grid xs={3} sm={3} md={3} lg={2}>
        <Card >
          <CardActionArea>
            <CardContent onClick={() => { handleOpenDialog('delete'); }}>
              <DeleteForeverIcon />
              <Typography gutterBottom  component="p">
                Delete
              </Typography>                  
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={dialogConfig.onConfirm}
        title={dialogConfig.title}
        description={dialogConfig.description}
        confirmButtonText={dialogConfig.confirmButtonText}
        confirmButtonColor={dialogConfig.confirmButtonColor}
      />
    </>
  );
};

export default CrudButtons;
