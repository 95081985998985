import React, { useEffect, useState, useRef } from 'react';
import Cite from "citation-js";
import { formatISO } from 'date-fns';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography'; 
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useStore } from '../services/store';
import { makeRequest } from '../services/query';
import { NewBibliographyModal } from './MimaModals';
import { 
  goToRecord  
} from '../helpers/buttonActions';

require('citation-js')


export const Bibliography = ({ currData, parent }) => {
  console.log("Render Bibliography")
  const [open, setOpen] = useState(false);
  const [bibliography, setBibliography] = useState(new Cite());
  const [newData, setNewData] = useState(new Cite());
  const [formattedHtml, setFormattedHtml] = useState(null);
  const [citations, setCitations] = useState([]); // State for unique citations
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);
  const activeActor = useStore(state => state.activeActor);
  const uuid = currData?.uuid;
  const formattedBiblio = useRef(null);

  //useEffect( () => {
    //if (currData) {
      //setBibliography(prevBiblio => {
        //const newBiblio = new Cite(...prevBiblio); // Create new Cite object with spread operator
        //newBiblio.add(currData?.bibliography || null);
        //return newBiblio;
      //});
    //}
  //}, [currData]);

  useEffect( () => {
    if (currData) {
      setBibliography(new Cite(currData?.bibliography || null));
    }
  }, [currData]);


  useEffect(() => {
    if (bibliography) {
      const newFormattedHtml = bibliography.format('bibliography', { format: 'html', template: 'apa' });
      setFormattedHtml(newFormattedHtml);
    }
  }, [bibliography]);

  useEffect(() => {
    if (formattedHtml) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(formattedHtml, 'text/html');

      const entries = doc.querySelectorAll('.csl-entry');
      const formattedCitations = [];
      for (const entry of entries) {
        const entryId = entry.dataset.cslEntryId; // Extract entry ID
        const citationText = entry.textContent.trim(); // Extract citation text
        formattedCitations.push({ id: entryId, text: citationText });
      }

      setCitations(formattedCitations);
    }
  }, [formattedHtml]);

  const handleEdit = () => {
    console.log('edit')
  };

  const handleDelete = (id) => {
    console.log('delete')
    bibliography.remove(id)
  };

  
  const saveData = async () => {
    console.log('Saving Bibliography');
    try {
      const combinedBiblio = bibliography.add(newData.format('data'));
      console.log(combinedBiblio)
      const updateData = {
        bibliography: combinedBiblio.format('data'), 
        updated_at: formatISO(new Date()), 
        updated_by: activeActor?.uuid || null
      };
      const updatedRow = await makeRequest(
                                'PATCH', 
                                `edit_bibliography?uuid=eq.${uuid}`,
                                updateData, 
                                "Prefer: return=representation"
                              );
      console.log('Bibliography updated: ', updatedRow);     
      setSnackbarData ({
        "actionType": "save",
        "messageType": "success",
        "messageText": "Bibliography saved."
      });
    } catch (error) {
      console.log('Error saving data: ', error);     
      setSnackbarData ({
        "actionType": "save",
        "messageType": "error",
        "messageText": "Save failed: \n\n" + error.message + " \n " + error.response?.data.message
      });
    }
    setSnackbarOpen(true)
    setNewData(new Cite());
    setOpen(false);
  };  

  if (!currData?.bibliography) {
    return (
      <div className="fill-main" id="biblio">
       <Grid container spacing={2}>
          <Grid xs={12}>

          <Typography variant="h6" component="div" sx={{ textAlign: 'center', padding: 2 }}>
            No bibliography found.
          </Typography>
          </Grid>
          <Grid xs={12}>
            <Card >
              <CardActionArea>
                <CardContent onClick={() => { setOpen(true); }}>
                  <AddIcon />
                  <Typography gutterBottom variant="b" component="p">
                    Add New Bibliographic Reference
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <NewBibliographyModal
        open={open}
        setOpen={setOpen}
        newData={newData}
        setNewData={setNewData}
        saveData={saveData}
        />         
      </div>
    );
  }  

  return (
    <div className="fill-main" id="biblio">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography variant="h6" component="p"  sx={{ padding: 1 }}>
            Bibliography
          </Typography>        
            {/* Map through the links array to generate the list of Paper Cards */}
            <Grid container spacing={2} ref={formattedBiblio}>
              {citations.map((citation, index) => (
                <Grid xs={12}>
                  <Paper key={citation.id} sx={{ padding: '16px' }}>
                    {/* Render the citation text within Paper */}
                    {citation.text}
                    <IconButton aria-label="edit" size="small" onClick={() => handleEdit()}>
                      <Edit />
                    </IconButton>
                    <IconButton aria-label="delete" size="small" onClick={() => handleDelete(citation.id)}>
                      <Delete />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
            </Grid>
        </Grid>
        <Grid xs={12}>
          <Card >
            <CardActionArea>
              <CardContent onClick={() => { setOpen(true); }}>
                <AddIcon />
                <Typography gutterBottom variant="b" component="p">
                  Add New Bibliographic Reference
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <NewBibliographyModal
        open={open}
        setOpen={setOpen}
        newData={newData}
        setNewData={setNewData}
        saveData={saveData}
      />    
    </div>
  );
};
