import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography'; 
import { useStore } from '../services/store';
import TableDrawer from '../components/TableDrawer';
import {ReadMap} from "../components/MimaMap";
import { makeRequest } from '../services/query';
import { Threed } from '../components/Mima3d';
import { MapIcon } from '../components/LocalIcons';
import { FullPage } from "../components/Sheets";


const NavRight = () => {
  function scrollto(id, instant){
    if(instant && navigator.userAgent.match(/chrome|chromium|crios|edg/i)){
      document.getElementById(id).scrollIntoView();
    } else {
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
    };
  }
  return (
    <div className="w3-center w3-xlarge w3-text-white w3-display-right w3-dark-grey w3-round-xxlarge w3-margin rightMenu">
      <span className="fa fa-map-o fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('map')} style={{cursor:"pointer"}} />
      <span className="fa fa-eye fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('3d')} style={{cursor:"pointer"}} />      
    </div>
    );
};



const Map = () => {
  console.log("Render Map")
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const setTableOpen = useStore(state => state.setTableOpen);

  const apiTable = 'v_place'

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await makeRequest('get', `${apiTable}`, {}, {});
      setPlaces(data);
      setLoading(false);
    };   
    getData();
    console.log("PLaces loaded.")
  }, []);


  const listColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
      editable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 2, 
      editable: false,
    },      
    { field: 'authority', 
      headerName: 'Authority', 
      flex: 3, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'geojson', 
      headerName: 'GeoJSON', 
      flex: 3, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },      
  ]  


  return (
    <>
      <header className="w3-container" >
        <Typography variant="h5" gutterBottom >
          <MapIcon /> Map
        </Typography>
        <a href="#" className="w3-bar-item w3-button w3-padding w3-right" type="button" onClick={() => setTableOpen(true)}>
          <i className="fa fa-list fa-fw"></i>  View list
        </a>
      </header>
      <div className="row">
        <FullPage>
        <ReadMap currData={''} parent={''} />
        <Threed />  
        </FullPage>
      </div>
      <NavRight />
      <TableDrawer 
        columns={listColumns}
        listTable={apiTable}
      />      
    </>
    );
};

export default Map;
