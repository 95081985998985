import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { useStore } from '../services/store';
import useCrudActions from '../components/CrudActions';
import CrudButtons from '../components/DesktopCrudButtons';
import {EntryNavigator } from '../components/DesktopButtons';
import Typography from '@mui/material/Typography'; 
import LitFields from '../components/LitFields';
import TableDrawer from '../components/TableDrawer';
import { formatDateString, dateSortComparator } from '../components/Table';
import { ReadMap } from '../components/MimaMap';
import { Bibliography } from '../components/MimaBibliography';
import { Images, Documents, Links } from '../components/MimaResources';
import Relationships from '../components/MimaRels';
import Audit from '../components/MimaAudit';
import {HalfPage} from "../components/Sheets";
import {InventoryFields, DatingFields, PlaceFields, PrisonFields, BookFields, TranslationFields, CommentaryFields} from '../components/CommonFields';
import {LiteraryIcon } from '../components/LocalIcons';
import { makeRequest } from '../services/query';
import { renderBooleanCell } from '../helpers/tableRenders';


const NavLeft = () => {
  function scrollto(id, instant){
    if(instant && navigator.userAgent.match(/chrome|chromium|crios|edg/i)){
      document.getElementById(id).scrollIntoView();
    } else {
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
    };
  }
  return (
    <div className="w3-center w3-xlarge w3-text-white w3-dark-grey w3-round-xxlarge leftMenu" >
      <span className="fa fa-info-circle fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('info')} style={{cursor:"pointer"}} />
      <span className="fa fa-language fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('translation')} style={{cursor:"pointer"}} />
      <span className="fa fa-comment fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('commentary')} style={{cursor:"pointer"}} />
      {false &&
      <span className="fa fa-book fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('book')} style={{cursor:"pointer"}} />
      }
    </div>
    );
};

const NavRight = () => {
  function scrollto(id, instant){
    if(instant && navigator.userAgent.match(/chrome|chromium|crios|edg/i)){
      document.getElementById(id).scrollIntoView();
    } else {
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
    };
  }
  return (
    <div className="w3-center w3-xlarge w3-text-white w3-display-right w3-dark-grey w3-round-xxlarge w3-margin rightMenu">
      <span className="fa fa-map-o fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('map')} style={{cursor:"pointer"}} />
      <span className="fa fa-sitemap fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('rel')} style={{cursor:"pointer"}} />
      <span className="fa fa-link fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('links')} style={{cursor:"pointer"}} />
      <span className="fa fa-picture-o fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('images')} style={{cursor:"pointer"}} />
      <span className="fa fa-file-text-o fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('documents')} style={{cursor:"pointer"}} />
      <span className="fa fa-book fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('biblio')} style={{cursor:"pointer"}} />
      <span className="fa fa-eye fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('audit')} style={{cursor:"pointer"}} />
    </div>
    );
};

const LeftHalf = ({ currData, setCurrData }) => {
  if(!currData){
    return (
      <div className="fill-main" id="form">
        <CrudButtons rowSelected={false} />
      </div>
    )    
  };
  return (
    <>
      <div className="fill-most" >
        <div className="fill-most" id="info" >
          <Grid container spacing={2}  >      
            <InventoryFields currData={currData} setCurrData={setCurrData}/>        
            <LitFields currData={currData} setCurrData={setCurrData} />
            <DatingFields currData={currData} setCurrData={setCurrData} />
            <PrisonFields currData={currData} setCurrData={setCurrData} />
          </Grid>
        </div>
        <div className="fill-most" id="translation" >
          <Grid container spacing={2}  >      
            <TranslationFields currData={currData} setCurrData={setCurrData} />
          </Grid>
        </div>
        <div className="fill-most" id="commentary" >
          <Grid container spacing={2}  >      
            <CommentaryFields currData={currData} setCurrData={setCurrData} />
          </Grid>
        </div> 
        {false &&
        <div className="fill-most" id="book" >
          <Grid container spacing={2}  >      
            <BookFields currData={currData} setCurrData={setCurrData} />
          </Grid>
        </div>
        } 
      </div>           
      <Grid container spacing={2} padding  > 
        <CrudButtons rowSelected={true} />
      </Grid>
      <NavLeft />
    </>
  )

};

const Literary = () => {
  let { id } = useParams(); // Grabs the entryID from the URL  
  console.log("Render Literary")
  const setTableOpen = useStore(state => state.setTableOpen);
  const currData = useStore(state => state.currLit);
  const setCurrData = useStore(state => state.setCurrLit);
  const setCurrUuid = useStore(state => state.setCurrUuid);
  const setCurrEntryId = useStore(state => state.setCurrEntryId);  
  const [dataForDb, setDataForDb] = useState(null);

  const navigate = useNavigate();

  //Preparing object for writing to database
  useEffect(() => {
    if (currData) {
      const { index_topic_names, keyword_names, tag_names, links, geom, placename, place_authority, ...preppedData } = currData;
      setDataForDb(preppedData);
    };
    if (currData?.uuid) {
      setCurrUuid(currData.uuid);
    };
    if (currData?.entry_id) {
      setCurrEntryId(currData.entry_id);
    };    
  }, [currData]); // This effect runs whenever `currData` changes


  const apiTable = 'v_literary'

  useCrudActions({
    setSelectedRowData: setCurrData,
    selectedRowData: dataForDb,
    viewTable: apiTable,  
    editTable: 'edit_literary'
  });

  useEffect(() => {
    if (id){
      const loadData = async () => {
        try {
          const getData = await makeRequest('get', `${apiTable}?entry_id=eq.${id}`, {}, {});
          setCurrData(getData[0])
        } catch (error) {
          console.error('Could not load data for record:', error);
        }
      };
      loadData();
    } else if(currData){
      navigate(currData.entry_id)
    } else {
      setTableOpen(true)
    }
  }, [id]);



  const idCol = 'entry_id'

  const listColumns = [
    { field: 'uuid', 
      headerName: 'UUID', 
      flex: 2, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true,
    },      
    { field: 'entry_id', 
      headerName: 'Id', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'entry_name', 
      headerName: 'Name', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'placename', 
      headerName: 'Place', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },    
    {
      field: 'dateStart',
      headerName: 'TPQ',
      flex: 1,
      valueGetter: (params) => {
        const matches = params.row.dating && params.row.dating.match(/\[(?:"?)(.*?)(?:"?)\s*,/);
        if (matches && matches[1]) {
          return formatDateString(matches[1]);
        }
        return '';
      },
      sortComparator: dateSortComparator,      
    },
    {
      field: 'dateEnd',
      headerName: 'TAQ',
      flex: 1,
      valueGetter: (params) => {
        const matches = params.row.dating && params.row.dating.match(/,\s*(?:"?)(.*?)(?:"?)\)/);
        if (matches && matches[1]) {
          return formatDateString(matches[1]);
        }
        return '';
      },
      sortComparator: dateSortComparator,
    }, 
    { field: 'index_topic_names', 
      headerName: 'Index topics', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    }, 
    { field: 'tag_names', 
      headerName: 'Tags', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'complete', 
      headerName: 'Complete', 
      flex: 1, 
      type: 'boolean', 
      renderCell: renderBooleanCell, 
      width: 50,
      sortable: true
    },      
  ]

  return (
    <>
      <header className="w3-container" >
        <Typography variant="h5" gutterBottom >
          <LiteraryIcon /> Literary Sources
        </Typography>
        <EntryNavigator
          navigate={navigate}
          entryId={id}
        />        
        <a href="#" className="w3-bar-item w3-button w3-padding w3-right" type="button" onClick={() => setTableOpen(true)}>
          <i className="fa fa-list fa-fw"></i>  View list
        </a>
      </header>
      <div className="row">
        <HalfPage>
          <LeftHalf currData={currData} setCurrData={setCurrData} />

          
        </HalfPage>
        <HalfPage>

          <ReadMap 
            currData={currData}
            setCurrData={setCurrData}
            parent={'literary'}
          />
          <div className="thirtyheight">
            <Grid container spacing={2} paddingTop > 
              <PlaceFields currData={currData} setCurrData={setCurrData} />
            </Grid>
          </div>  
          <Relationships
            currData={currData}
            setCurrData={setCurrData}
            parent={'literary'}
          />
          <Links
            parent={'literary'}
          />
          <Images
            parent={'literary'}
          />
          <Documents
            parent={'literary'}
          />   
          <Bibliography
            currData={currData}
            setCurrData={setCurrData}
            parent={'literary'}
          />
          <Audit 
            parent={'literary'}
          />                                         
          <div className="fill-main" id="table">
          </div>          
          <NavRight />
        </HalfPage>        
      </div>
      <TableDrawer 
        columns={listColumns}
        listTable={'list_literary'}
        idCol={idCol}
      />      
    </>
    );
};

export default Literary;
