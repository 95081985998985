import React, { useEffect, useState } from 'react';
import { useStore } from '../services/store';
import TableDrawer from '../components/TableDrawer';
import Typography from '@mui/material/Typography';
import { FullPage } from "../components/Sheets";
import { makeRequest } from '../services/query';
import RelationshipGraph from '../components/RelationshipGraph';
import { RelationshipIcon } from '../components/LocalIcons';


const NavRight = () => {
  function scrollto(id, instant){
    if(instant && navigator.userAgent.match(/chrome|chromium|crios|edg/i)){
      document.getElementById(id).scrollIntoView();
    } else {
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
    };
  }
  return (
    <div className="w3-center w3-xlarge w3-text-white w3-display-right w3-dark-grey w3-round-xxlarge w3-margin rightMenu">
      <span className="fa fa-list fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('table')} style={{cursor:"pointer"}} />
      <span className="fa fa-link w3-transparent w3-hover-shadow" onClick={() => scrollto('records')} style={{cursor:"pointer"}} />
      <span className="fa fa-map-o fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('map')} style={{cursor:"pointer"}} />
      <span className="fa fa-eye fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('audit')} style={{cursor:"pointer"}} />      
    </div>
    );
};



const Relationships = () => {
  console.log("Render Relationships")
  const [rels, setRels] = useState([]);
  const [loading, setLoading] = useState(false);
  const setTableOpen = useStore(state => state.setTableOpen);

  const apiTable = 'v_crosslist'

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await makeRequest('get', `${apiTable}`, {}, {});
      setRels(data);
      setLoading(false);
    };   
    getData();
    console.log("Relationships loaded.")
  }, []);


  const listColumns = [
    {
      field: 'parent',
      headerName: 'Parent',
      flex: 1,
      editable: false,
    },
    {
      field: 'child',
      headerName: 'Child',
      flex: 1, 
      editable: false,
    },      
    { field: 'relationship', 
      headerName: 'Relationship', 
      flex: 3, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'comment', 
      headerName: 'Comment', 
      flex: 4, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },      
  ]  


  return (
    <>
      <header className="w3-container" >
        <Typography variant="h5" gutterBottom >
          <RelationshipIcon /> Relationships
        </Typography>
        <a href="#" className="w3-bar-item w3-button w3-padding w3-right" type="button" onClick={() => setTableOpen(true)}>
          <i className="fa fa-list fa-fw"></i>  View list
        </a>
      </header>
      <div className="row">
        <FullPage>
          <div className="fill-main" id="rel">
            <RelationshipGraph rels={rels} currUUID={''} />
          </div>
        </FullPage>
      </div>
      <TableDrawer 
        columns={listColumns}
        listTable={apiTable}
      />      
    </>
    );
};

export default Relationships;
