import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import { isMobile } from 'react-device-detect';
//import { hasRole } from './helpers/authHelpers';
import { CircularLoading } from './components/Loading';
//import setupAxiosInterceptors from './services/setTokenHeader'
import { useStore } from './services/store';
import Layout from "./pages/Layout";
import Overview from "./pages/MimaOverview";
import Documentary from "./pages/MimaDocumentary";
import Literary from "./pages/MimaLiterary";
import Archaeological from "./pages/MimaArchaeological";
import Visual from "./pages/MimaVisual";
import Relationships from "./pages/MimaRelationships";
import Vocabulary from "./pages/MimaVocabulary";
import Media from "./pages/MimaMedia";
import Map from "./pages/MimaMap";
import About from "./pages/MimaAbout";
import Settings from "./pages/Settings";
import SurveyMenu from "./pages/SurveyMenu";
import SurveyLine from "./pages/SurveyLine";
import SurveyTract from "./pages/SurveyTract";
import SurveyFeature from "./pages/SurveyFeature";
import SurveyPhoto from "./pages/SurveyPhoto";
import SurveyScape from "./pages/SurveyScape";
import DesktopScape from "./pages/DesktopScape";
import SurveyFind from "./pages/SurveyFind";
import NoPage from "./pages/NoPage";
import SurveyGeometry from "./pages/SurveyGeometry"
import SurveySelTract from "./pages/SurveySelTract"
import SurveySelLine from "./pages/SurveySelLine"
import SurveySelFeat from "./pages/SurveySelFeat"
import SurveySelScape from "./pages/SurveySelScape"
import SurveySelGrid from "./pages/SurveySelGrid"
import SurveyGrid from "./pages/SurveyGrid"
import SurveySelSample from "./pages/SurveySelSample"
import SurveySample from "./pages/SurveySample"
import { useSurveyData, useMimaData } from "./services/getData"

import axios from "axios";


export default function App() {
  const { keycloak, initialized } = useKeycloak();
  const modules = useStore.getState().modules;
  const fetchSurveyData = useSurveyData();
  const fetchMimaData = useMimaData();
  const userRole = useStore(state => state.userRole);
  const setUserRole = useStore(state => state.setUserRole);
  const popData = useStore(state => state.popData);


  // Updates the axios interceptors whenever the token changes
//  useEffect(() => {
//    if (keycloak.token) {
//      // When axios makes API calls the token will be sent automatically      
//      setupAxiosInterceptors(keycloak.token);
//      console.log("Authentication bearer set.")
//
//    }
//  }, [keycloak.token]);
//
//  // Check token validity and refresh if necessary before making a request
//  useEffect(() => {
//    if (keycloak.authenticated) {
//      try {
//        keycloak.updateToken(5)
//            .then(refreshed => {
//              if (refreshed) {
//                console.log('Token was successfully refreshed');
//              } else {
//                console.log('Token is still valid');
//              }
//            })      
//      } catch (error) {
//        console.error('Failed to refresh token', error);
//        throw error;
//      }
//    }
//  }, [keycloak.authenticated, keycloak.token]);
//
//  if (isMobile) {
//    console.log("Mobile detected")
//  } else {
//    console.log("Desktop detected")
//
//  }

  // Setup the Axios interceptor
  useEffect(() => {
    // Ensure the interceptor is set up only once
    const interceptor = axios.interceptors.request.use(config => {
      if (keycloak.token) {
        config.headers.Authorization = `Bearer ${keycloak.token}`;
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });
    
    // Eject the interceptor when component unmounts
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, []); // Empty dependency array to set it up only on mount

  // Token refresh logic
  useEffect(() => {
    if (keycloak.authenticated) {
      const refreshInterval = setInterval(() => {
        keycloak.updateToken(70) // Set minValidity to a reasonable time, like 70 seconds before it expires
          .then(refreshed => {
            if (refreshed) {
              console.log('Token was successfully refreshed');
            } else {
              console.log('Token is still valid');
            }
          })
          .catch(error => {
            console.error('Failed to refresh token', error);
          });
      }, 60000); // Check every minute as an example

      // Clean up interval on component unmount
      return () => clearInterval(refreshInterval);     
    }
  }, [keycloak.authenticated, keycloak.token]);  


  useEffect(() => {
    if (keycloak.authenticated && !popData) {
      if (modules.includes("survey")){
        fetchSurveyData();
      };
      if (modules.includes("mima")){
        fetchMimaData();
      };
      // get user role from Keycloak
      setUserRole(keycloak.resourceAccess?.mima?.roles[0])
    }
  }, [keycloak.token, fetchSurveyData, fetchMimaData]);

  // When component mounts
  useEffect(() => {
    // Add class to body
    document.body.className = 'w3-light-grey';
    // When component unmounts
    return () => {
      // Remove class from body
      document.body.className = '';
    }
  }, []);


  if (!initialized) {
    return <CircularLoading/>;
  } else if (keycloak.authenticated && !userRole) {
    return (
      <div> 
        <p>User has no access privileges. If you recently registered, contact NN to get access. </p>
        <button className="w3-button" type="button" onClick={() => keycloak.logout()}>
         Logout
      </button>
      </div>
     );
  }

  return (
    <>
      {keycloak.authenticated && (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              {modules.includes("mima") && 
                <>            
                  <Route index element={<Overview />} />
                  <Route path="Documentary" element={<Documentary />}>
                    <Route path=":id" element={<Documentary />} />
                  </Route>              
                  <Route path="Literary" element={<Literary />} >
                    <Route path=":id" element={<Literary />} />
                  </Route>          
                  <Route path="Archaeological" element={<Archaeological />} >
                    <Route path=":id" element={<Archaeological />} />
                  </Route>
                  <Route path="Visual" element={<Visual />} >
                    <Route path=":id" element={<Visual />} />
                  </Route>
                  <Route path="Vocabulary" element={<Vocabulary />} >
                    <Route path=":type" element={<Vocabulary />}>
                      <Route path=":id" element={<Vocabulary />} />
                    </Route>
                  </Route>
                  <Route path="Relationships" element={<Relationships />} />
                  <Route path="Media" element={<Media />} >
                    <Route path=":id" element={<Media />} />
                  </Route>
                  <Route path="Map" element={<Map />} />
                  <Route path="About" element={<About />} />

                </>
              }
              {modules.includes("survey") && 
                <>
                  <Route path="/" element={<SurveyMenu />} />


                  <Route path="surv_tract" element={<SurveyTract />} >  
                    <Route path="select" element={<SurveySelTract />} />
                    <Route path="photo" element={<SurveyPhoto parent={'tract'} />} />
                  </Route>
                  <Route path="surv_line" element={<SurveyLine />} >
                    <Route path="select" element={<SurveySelLine />} />
                    <Route path="find" element={<SurveyFind parent={'line'} />} >
                      <Route path="photo" element={<SurveyPhoto parent={'find'} />} />
                    </Route>
                  </Route>

                  <Route 
                    path="surv_scape" 
                    element={ 
                      isMobile ? 
                      <SurveyScape /> :
                      <DesktopScape />
                    } 
                  >     
                    <Route path="select" element={<SurveySelScape />} />                               
                    <Route path="geom" element={<SurveyGeometry parent={'scape'} />} />
                    <Route path="photo" element={<SurveyPhoto parent={'scape'} />} />
                  </Route>

                  <Route path="surv_feature" element={<SurveyFeature />} >
                    <Route path="select" element={<SurveySelFeat />} />  
                    <Route path="geom" element={<SurveyGeometry parent={'feature'} />} />
                    <Route path="photo" element={<SurveyPhoto parent={'feature'} />} />
                  </Route>

                  <Route path="surv_grid" element={<SurveyGrid />} >
                    <Route path="select" element={<SurveySelGrid />} />                               
                    <Route path="geom" element={<SurveyGeometry parent={'grid'} />} />   
                    <Route path="photo" element={<SurveyPhoto parent={'grid'} />} />                                 
                    <Route path="find" element={<SurveyFind parent={'grid'} />} >
                      <Route path="photo" element={<SurveyPhoto parent={'find'} />} />
                    </Route>
                  </Route>

                  <Route path="surv_sample" element={<SurveySample />} >
                    <Route path="select" element={<SurveySelSample />} />  
                  </Route>

                  <Route path="surv_map" element={<SurveyGeometry />} />

                  <Route path="surv_find" element={<SurveyFind />} >
                    <Route path="photo" element={<SurveyPhoto parent={'find'} />} />
                  </Route>

                </>
              }
              <Route path="settings" element={<Settings />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      )}        
    </>
  );
}
