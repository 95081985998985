import React, { useEffect, useRef, useState } from "react";
import { useStore } from '../services/store';
import L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import { makeRequest } from '../services/query';
//import useCrudActions from '../components/CrudActions';
import leafletMarkerIcon from 'leaflet/dist/images/marker-icon.png';
import leafletMarkerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import leafletMarkerIconShadow from 'leaflet/dist/images/marker-shadow.png';

const defaultPosition = [35, 15];


export const EditMap = ({currData, setCurrData, parent}) => {
  const mapRef = useRef();

  const [dataForDb, setDataForDb] = useState(null);
  const [geoJsonForMap, setGeoJsonForMap] = useState(null);
  const [staticGeometries, setStaticGeometries] = useState(null);
  const buttonAction = useStore(state => state.buttonAction);
  const setButtonAction = useStore(state => state.setButtonAction);    

  useEffect(() => {
    if (currData?.place){
      const getData = async () => {
        const data = await makeRequest('get', `v_place?uuid=eq.${currData?.place}`, {}, {});
        setStaticGeometries(data[0].geojson);
        console.log("Place loaded.")      
        console.log(data)
      };   
      getData();
    }    
    let geometryData = {}
    if (typeof currData?.geom === 'string') {
    geometryData = JSON.parse(currData?.geom);
    } else {
        geometryData = currData?.geom
    }
    console.log("Loaded geometries")
    console.log(currData?.geom)    
    setGeoJsonForMap(geometryData);
  }, [currData]); 

  useEffect(() => {
    console.log('geoJsonForMap: ', geoJsonForMap)
  }, [geoJsonForMap]); 

  //useCrudActions({
    //setSelectedRowData: setCurrData,
    //selectedRowData: dataForDb,
    //viewTable: `v_${parent}`,  
    //editTable: `edit_${parent}`
  //});
 
  const updateButtonAction = (actionName, value) => {
    const updatedAction = { ...buttonAction, [actionName]: value };
    setButtonAction(updatedAction);
  };

  function featureCollectionToGeometryCollection(featureCollection) {
    if (!featureCollection || featureCollection.type !== "FeatureCollection") {
      return null;
    }
  
    const geometryCollection = {
      type: "GeometryCollection",
      geometries: featureCollection.features.map(feature => feature.geometry)
    };
  
    return geometryCollection;
  }

  // Using a ref to always have the current `currData`
  const currDataRef = useRef(currData);
  useEffect(() => {
    currDataRef.current = currData;
  }, [currData]);
  useEffect(() => {
    console.log('dataForDb')
    console.log(dataForDb)
    setCurrData(dataForDb)
  }, [dataForDb]);

  function saveLayers() {
    // Use current value from ref  
    const currentData = currDataRef.current;
    const geomanLayers = mapRef.current.pm.getGeomanLayers();
    const featureGroup = L.featureGroup(geomanLayers);  
    const data = featureGroup.toGeoJSON();  
    console.log(data);
    const geometryCollection = featureCollectionToGeometryCollection(data);
    console.log(geometryCollection);
//    let updatedData = { ...currentData, geom: geometryCollection };
//    setCurrData(updatedData);
//    console.log('updatedData')
//        console.log('currData')
//    console.log(currData)
//
    console.log('currentData')
    console.log(currentData)
    setDataForDb({...currentData, "geom": geometryCollection});
    //updateButtonAction("save", true)
  }

  // Set up default Leaflet icon properties
  const DefaultIcon = L.icon({
      iconRetinaUrl: leafletMarkerIconRetina,
      iconUrl: leafletMarkerIcon,
      shadowUrl: leafletMarkerIconShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
  });

  // Tell Leaflet to use this icon for all markers
  L.Marker.prototype.options.icon = DefaultIcon;

  const GoogleSat = L.gridLayer.googleMutant({
    type: "satellite", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
  });


  const EsriSatBasemap = L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {
  attribution: 'Map data © Esri',
  });
  const EsriTopoBasemap = L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}", {
  attribution: 'Map data © Esri',
  });
  const EsriHillshadeBasemap = L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}", {
  attribution: 'Map data © Esri',
  });
  const DAREBasemap = L.tileLayer(" https://dh.gu.se/tiles/imperium/{z}/{x}/{y}.png", {
  attribution: 'Map data from DARE, CC-BY 4.0 Johan Åhlfeldt, Centre for Digital Humanities University of Gothenburg',
  });


 

  const baseMaps = {
      "DARE": DAREBasemap,
      "Google Sat": GoogleSat,
      "Esri Sat": EsriSatBasemap,
      "Esri Topo": EsriTopoBasemap,
      "Esri Shade": EsriHillshadeBasemap
  }


  useEffect(() => {
    if (!mapRef.current) {      
      mapRef.current = L.map("map", {
        center: defaultPosition,
        zoom: 3,
        maxZoom: 20,
        layers: [
          EsriSatBasemap
        ],
      });
      mapRef.current.pm.Toolbar.createCustomControl({
        name: 'save',
        block: 'custom',
        className: 'fa fa-floppy-o mapicon',
        title: 'Save the map',
        onClick: () => {
          saveLayers(); 
        },
      });
      mapRef.current.pm.addControls({
        positions: {
          draw: "topleft",
          edit: "topleft",
          custom: "topleft",
        },
        oneBlock: true,
        cutPolygon: false,
        rotateMode:false,
        drawMarker: true,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawText: false
      });
      L.control.layers(baseMaps).addTo(mapRef.current);
    };
  }, []);

  useEffect(() => {    
      // Add GeoJSON to map
      const editLayer = L.geoJSON(geoJsonForMap).addTo(mapRef.current);
      //console.log("Editlayer:");
      //console.log(editLayer)
      editLayer.pm.enable(); // This makes the layer a Geoman layer 
      const staticLayer = L.geoJSON(staticGeometries).addTo(mapRef.current);
      //console.log("StaticLayer:");
      //console.log(staticLayer)      
      // Check if there are any layers available to zoom to
      if (editLayer.getLayers().length > 0) {
        // Adjust the map view to ensure the entire layer is visible within the current map container
        try{
          mapRef.current.fitBounds(editLayer.getBounds(), {
            maxZoom: 10 // Set this to your desired max zoom level
          });
        } catch (error) {
          console.error('Error setting bounds:', error);
        }

      } else if (staticLayer.getLayers().length > 0) {
        try{
          // Adjust the map view to point
          mapRef.current.setView(staticLayer, 8);
        } catch (error) {
          console.error('Error setting map view:', error);
        }
      }  
     
  }, [geoJsonForMap, staticGeometries]);

  return (
    <div className="halfheight" id="map" style={{width:"100%"}} />
  );
};

export const ReadMap = ({currData, parent}) => {
  const mapRef = useRef();
  const [staticGeometries, setStaticGeometries] = useState(null);

  // Set up default Leaflet icon properties
  const DefaultIcon = L.icon({
      iconRetinaUrl: leafletMarkerIconRetina,
      iconUrl: leafletMarkerIcon,
      shadowUrl: leafletMarkerIconShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
  });

  // Tell Leaflet to use this icon for all markers
  L.Marker.prototype.options.icon = DefaultIcon;  

  useEffect(() => {
    if (currData?.place){
      const getData = async () => {
        const data = await makeRequest('get', `v_place?uuid=eq.${currData?.place}`, {}, {});
        setStaticGeometries(data[0].geojson);
        console.log("Place loaded.")      
        console.log(data)
      };   
      getData();
    }
  }, [currData]); 

  // Using a ref to always have the current `currData`
  const currDataRef = useRef(currData);
  useEffect(() => {
    currDataRef.current = currData;
  }, [currData]);

  const GoogleSat = L.gridLayer
    .googleMutant({
      type: "satellite", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
    });

  const EsriSatBasemap = L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {
  attribution: 'Map data © Esri',
  });
  const EsriTopoBasemap = L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}", {
  attribution: 'Map data © Esri',
  });
  const EsriHillshadeBasemap = L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}", {
  attribution: 'Map data © Esri',
  });  
  const DAREBasemap = L.tileLayer(" https://dh.gu.se/tiles/imperium/{z}/{x}/{y}.png", {
  attribution: 'Map data from DARE, CC-BY 4.0 Johan Åhlfeldt, Centre for Digital Humanities University of Gothenburg',
  });

  const baseMaps = {
      "DARE": DAREBasemap,
      "Google Sat": GoogleSat,
      "Esri Sat": EsriSatBasemap,
      "Esri Topo": EsriTopoBasemap,
      "Esri Shade": EsriHillshadeBasemap
  }


  useEffect(() => {
    if (!mapRef.current) {      
      mapRef.current = L.map("map", {
        center: defaultPosition,
        zoom: 3,
        maxZoom: 20,
        layers: [
          EsriSatBasemap
        ],
      });
      L.control.layers(baseMaps).addTo(mapRef.current);
    };
  }, []);  
  useEffect(() => {    
      // Add GeoJSON to map
      const staticLayer = L.geoJSON(staticGeometries).addTo(mapRef.current);
       console.log("StaticLayer:");
       console.log(staticLayer)      
      // Check if there are any layers available to zoom to
      if (staticLayer.getLayers().length > 0) {
        try{
          // Adjust the map view to point
          mapRef.current.setView(staticLayer, 8);
        } catch (error) {
          console.error('Error setting map view:', error);
        }
      }     
  }, [staticGeometries]);

  return (
    <div className="halfheight" id="map" style={{width:"100%",overflow:"hidden"}} />
  );
};
