import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../services/store';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { 
  DataGrid, 
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeRequest } from '../services/query';

export const formatDateString = (dateString) => {
    let formatted = dateString;
    if (formatted.includes('BC')) {
      formatted = formatted.replace(/\s*BC$/, ' BCE'); // Add space before BC for consistency
    }
    formatted = formatted.replace(/^0+/, ''); // Remove leading zeros
    return formatted;
  };

export const dateSortComparator = (v1, v2, param1, param2) => {
    // A helper function to handle BCE and convert date to a sortable value
    const getSortableDateValue = (dateString) => {
      const era = dateString.includes('BC') ? -1 : 1;
      dateString = dateString.replace(/ BC$/, ''); // Removes ' BC' if present
      const dateParts = dateString.split('-').map(part => parseInt(part, 10));
      const [year, month = 1, day = 1] = dateParts; // Default to Jan 1st if not full date
      // Construct a new Date or a comparable value using era and date parts
      const dateValue = (era === -1 ? -1 : 1) * (year * 10000 + month * 100 + day);
      return dateValue;
    };

    const dateValue1 = getSortableDateValue(v1);
    const dateValue2 = getSortableDateValue(v2);
    return dateValue1 < dateValue2 ? -1 : (dateValue1 > dateValue2 ? 1 : 0);
  };  

// Custom toolbar component
const CustomToolbar = () => {
  const userRole = useStore(state => state.userRole);
  return (
    <div>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Box sx={{ flexGrow: 1 }} />
        {(userRole === 'admin' || userRole === 'read_write') && 
          <GridToolbarExport />
        }
      </GridToolbarContainer>      
    </div>
  );
};

export function JsonCellExpand(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
        {JSON.stringify(props.value)}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, position: 'relative' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography component="pre" sx={{ overflow: 'auto', margin: 0, fontFamily: 'monospace', p: 2 }}>{JSON.stringify(props.value, 0, 2)}</Typography>
        </Box>          
      </Popover>
    </div>
  );
}

export function TextCellExpand(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
        {props.value}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, position: 'relative' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ overflow: 'auto', paddingRight: 4}}>{props.value}</Typography>
        </Box>          
      </Popover>
    </div>
  );
}

export const SelectTable = ({ columns, listTable, loading, setLoading, idCol }) => {
  const [rows, setRows] = useState([]);

  let idField = 'uuid'
  if (idCol) {
    idField = idCol
  }

  const navigate = useNavigate();
  const { type, id } = useParams();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const data = await makeRequest('get', listTable, {}, {});
        setRows(data);
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    };
    if(listTable){
      getData();
      console.log("listTable has changed:", listTable) 
    }
  }, [listTable]);

  function capitalizeFirstLetter(string) {
    if (!string) return string; // Return the original string if it's empty
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleRowSelected = async (selectionModel) => {
    console.log("Row change detected")

    if(listTable === 'list_vocab') {
      const selectedRowsData = rows.filter(row =>
        selectionModel.includes(row[idField])
      );
      const parent = capitalizeFirstLetter(selectedRowsData[0].parent);
      if(parent){
        navigate(`${parent}/${selectionModel[0]}`);
        return
      }     
    }
    if(type){
      navigate(`${type}/${selectionModel[0]}`);
    } else {
      navigate(`./${selectionModel[0]}`);
    }
  };

  return (
    <DataGrid 
      rows={rows}
      columns={columns}
      pageSize={5}
      getRowId={(row) => row[idField]}
      onRowSelectionModelChange={handleRowSelected}
      slots={{
        toolbar: CustomToolbar,
      }}
      slotProps={{ toolbar: { showQuickFilter: true } }}
      loading={loading}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterExcludeHiddenColumns: true,
          },
        },
        columns: {
          columnVisibilityModel: {
            // Hide column uuid, the other columns will remain visible
            uuid: false,
          },
        },        
      }}      
    />
  );
}

export const ViewTable = ( {columns, listTable, idCol} ) => {
  console.log("Render ViewTable")
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  
  let idField = 'uuid'
  if (idCol) {
    idField = idCol
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const data = await makeRequest('get', listTable, {}, {});
        setRows(data);
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    };
  
    getData();
    console.log("listTable has changed:", listTable);
  }, [listTable]);


  const handleRowSelected = async (selectionModel) => {
    if (selectionModel.length === 0) {
      console.log("Row change detected")
    } else {
      console.log("Row change detected")
      //const getData = await makeRequest('get', `${apiTable}?uuid=eq.${selectionModel[0]}`, {}, {});

    }
  };


  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={5}
      getRowId={(row) => row[idField]}
      onRowSelectionModelChange={handleRowSelected}
      slots={{
        toolbar: CustomToolbar,
      }}
      slotProps={{ toolbar: { showQuickFilter: true } }}
      loading={loading}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterExcludeHiddenColumns: true,
          },
        },
        columns: {
          columnVisibilityModel: {
            // Hide column uuid, the other columns will remain visible
            uuid: false,
          },
        },        
      }}   
    />
  );
};

// Assumes each column that requires data has defined the field
const createEmptyRow = (columns) => {
  const newRow = {};
  columns.forEach((column) => {
    // Field 'id' is a special case and is often handled differently
    // For other fields, simply initialize them with default values
    if (column.field !== 'uuid') {
      newRow[column.field] = column.default || '';
    }
  });
  // The createId function is responsible for providing a unique ID
  newRow.uuid = uuidv4();
  return newRow;
};

function EditToolbar(props) {
  const { setRows, rowSelectionModel, setRowSelectionModel, columns, handleSave, setRowsForDeletion } = props;

  const handleAdd = () => {
    const addRow = createEmptyRow(columns);
    setRows((oldRows) => [...oldRows, addRow]);
  };
  
  const handleDelete = () => {
    setRows(prevRows => prevRows.filter(row => !rowSelectionModel.includes(row.uuid)));
    setRowsForDeletion((oldRows) => [...oldRows, ...rowSelectionModel])
    setRowSelectionModel([]);
  };


  return (
    <GridToolbarContainer>
      <Button color="secondary" startIcon={<AddIcon />} onClick={handleAdd}>
        Add record
      </Button>
      <Button color="secondary" startIcon={<DeleteIcon />} onClick={handleDelete}>
        Remove selected
      </Button>      
      <Button color="primary" startIcon={<SaveIcon />} onClick={handleSave}>
        Save changes
      </Button>      
    </GridToolbarContainer>
  );
}

export const EditTable = ( {columns, rows, setRows, handleSave, setRowsForDeletion} ) => {
  console.log("Render EditTable")
  //const [rows, setRows] = useState([]);
  //const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [loading, setLoading] = useState(false);

//  useEffect(() => {
//    const getData = async () => {
//      setLoading(true);
//      try {
//        const data = await makeRequest('get', listTable, {}, {});
//        setRows(data);
//      } catch (error) {
//        // Handle error
//      } finally {
//        setLoading(false);
//      }
//    };
//
//    getData();
//    console.log("listTable has changed:", listTable);
//  }, [listTable]);


  const processRowUpdate = async (updatedRow) => {
    console.log("Row change detected")
    const updatedRows = rows.map((row) => (row.uuid === updatedRow.uuid ? updatedRow : row));
    setRows(updatedRows);
    return updatedRow;      
  };  

  const handleProcessRowUpdateError = (error) => {
    // Do something with the error (e.g. display an error message to the user)
    console.error(error);
  };

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      getRowId={(row) => row['uuid']}
      loading={loading}
      //editMode="row"
      //rowModesModel={rowModesModel}
      //onRowModesModelChange={handleRowModesModelChange}
      //onRowEditStop={handleRowEditStop}
      checkboxSelection
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      processRowUpdate={ (updatedRow, originalRow) => processRowUpdate(updatedRow)}
      onProcessRowUpdateError={handleProcessRowUpdateError}      
      slots={{
        toolbar: EditToolbar,
      }}
      slotProps={{
        toolbar: { setRows, rowSelectionModel, setRowSelectionModel, columns, handleSave, setRowsForDeletion },
      }}        
    />
  );
};


export const SearchTable = ({ listData, searchCol, listCol, columns, apiTable }) => {
  console.log("Render SearchTable")
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState("");
  const [typeFilter, setTypeFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState(null);
  const [selValue, setSelValue] = useState(null);

  function handleTypeChange(event, newValue) {
    if(newValue){
      setTypeFilter(`${listCol}=eq.${newValue}`)
    } else {
      setTypeFilter(null)
    }
    setSelValue(newValue)
  }

  function handleSearchChange(data) {
    console.log(data.target.value)
    if(data.target.value){
      setSearchFilter(`${searchCol}=ilike.%${data.target.value}%`)
    } else{
      setSearchFilter(null)
    }
  }

  useEffect(() => {
    let queryStringParts = [];
    if (typeFilter) {
      queryStringParts.push(typeFilter);
    }
    if (searchFilter) {
      queryStringParts.push(searchFilter);
    }
    let queryString = `${apiTable}${queryStringParts.length > 0 ? '?' : ''}${queryStringParts.join('&')}`;
    setListTable(queryString);
  }, [typeFilter, searchFilter, apiTable]);

  return (
    <Grid container spacing={1} padding >
      <Grid xs={12} sm={6} md={6} order={{ xs: 6 }}>
          <TextField
            label="Search"
            variant="outlined"
            id="search_term"
            name="search"
            fullWidth              
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                </InputAdornment>

              ),              
            }}
            onChange={(data) => handleSearchChange(data)}
          />  
      </Grid>
      { listCol &&
      <Grid xs={12} sm={6} order={{ xs: 6 }}>
        <Autocomplete
          id="select_type"
          options={listData}
          value={selValue}
          onChange={(event, newValue) => handleTypeChange(event, newValue)}
          renderInput={(params) => <TextField {...params} label="Select type" />}
          renderOption={(props, option) => <li {...props}>{option}</li>} 
        />
      </Grid>
      }
      <Grid xs={12} order={{ xs: 6 }}>          
        <SelectTable
          columns={columns}
          listTable={listTable}
          loading={loading}
          setLoading={setLoading}
        />
      </Grid>    
    </Grid>    
  );
};