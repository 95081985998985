import React, { useMemo } from 'react';
import SimpleMDEReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import '../assets/css/easyMDE.css';


const TextEditor = ({ id, currData, setCurrData }) => {



  const options = useMemo(() => ({
    toolbar: [
      "bold", 
      "italic",
      "strikethrough",
      "heading",
      "|",
      "unordered-list",
      "ordered-list",
      "quote",
      "table",
      "horizontal-rule",
      "|",
      "undo",
      "redo",
      "|",
      "preview",
      "fullscreen",
      "|",      
      "guide"
    ],
    minHeight: "150px",
  }), []); // The empty dependency array means the options will only be created once on mount
  
  function onChange (value) {
    let updatedData = { ...currData, [id]: value };
    setCurrData(updatedData);
  };

  return (     
    <SimpleMDEReact
      id={id}
      //label="Your label"
      value={currData[id]} 
      onChange={onChange}
      options={options}
      style={{width:"100%"
      }}
    />                                     
  );
};

export default TextEditor;