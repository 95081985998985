import React from 'react';
import Typography from '@mui/material/Typography'; 
import { SettingsIcon } from '../components/LocalIcons';
import { FullPage } from "../components/Sheets";


const Settings = () => {
  console.log("Render Settings")

  return (
    <>
      <header className="w3-container" >
        <Typography variant="h5" gutterBottom >
          <SettingsIcon /> Settings
        </Typography>
      </header>
      <div className="row">
        <FullPage>
          <Typography variant="p"  >
          Currently no user configurable settings implemented.
          </Typography>  
        </FullPage>            
      </div>   
    </>
    );
};

export default Settings;
