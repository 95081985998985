import React, { useEffect } from 'react';
import { Link, NavLink  } from 'react-router-dom';
import { useStore } from '../services/store';
import BiotechIcon from '@mui/icons-material/Biotech';
import {DocumentaryIcon, LiteraryIcon, ArchIcon, VisualIcon, OverviewIcon, RelationshipIcon, TermIcon, MediaIcon, MapIcon, AboutIcon } from './LocalIcons';



const Sidebar = ({sidebarVisible, setSidebarVisible}) => {
  const modules = useStore.getState().modules;
  
  // Close the sidebar with the close button
  function w3_close() {
    setSidebarVisible(false);
  }

  useEffect(() => {
    // Get the Sidebar
    var menuSidebar = document.getElementById("menuSidebar");
    
    // Get the DIV with overlay effect
    var overlayBg = document.getElementById("myOverlay");    

    if (sidebarVisible) {
      menuSidebar.style.display = 'block';
      overlayBg.style.display = 'block';
    } else {
      menuSidebar.style.display = 'none';
      overlayBg.style.display = 'none';      
    }
  }, [sidebarVisible]);  

  return (
    <>
      <nav className="w3-sidebar w3-collapse w3-white w3-animate-left" style={{zIndex:1001,width:"200px"}} id="menuSidebar"><br />
        <div className="w3-bar-block">
          <Link className="w3-bar-item w3-button w3-padding-16 w3-hide-large w3-dark-grey w3-hover-black" onClick={() => w3_close()} title="close menu"><i className="fa fa-remove fa-fw"></i>  Close Menu</Link>
          {modules.includes("survey") &&
            <>
              <NavLink to='/' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              } ><i className="fa fa-home fa-fw"></i>  Home</NavLink>
              <NavLink to='/surv_line' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              } ><i className="fa fa-long-arrow-up fa-fw"></i>  Line</NavLink>
              <NavLink to='/surv_tract' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              } ><i className="fa fa-square-o fa-fw"></i>  Tract</NavLink>
              <NavLink to='/surv_scape' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><i className="fa fa-bullseye fa-fw"></i>  Scape</NavLink>              
              <NavLink to='/surv_feature' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><i className="fa fa-map-marker fa-fw"></i>  Feature</NavLink>
              <NavLink to='/surv_sample' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><BiotechIcon />  Sample</NavLink>              
              <NavLink to='/surv_map' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><i className="fa fa-map-o fa-fw"></i>  Map</NavLink>              
              <NavLink to='/surv_map' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><i className="fa fa-book fa-fw"></i>  Resources</NavLink>                            
            </>    
          }
          {modules.includes("mima") &&
            <>
              <NavLink to='/' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              } ><OverviewIcon />  Overview</NavLink>
              <NavLink to='/Documentary' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              } ><DocumentaryIcon />  Documentary</NavLink>
              <NavLink to='/Literary' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              } ><LiteraryIcon />  Literary</NavLink>
              <NavLink to='/Archaeological' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><ArchIcon />  Archaeological</NavLink>
              <NavLink to='/Visual' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><VisualIcon />  Visual</NavLink>
              <NavLink to='/Relationships' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><RelationshipIcon />  Relationships</NavLink>              
              <NavLink to='/Vocabulary/Term' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><TermIcon />  Vocabulary</NavLink>              
              <NavLink to='/Media' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><MediaIcon/>  Media</NavLink>
              <NavLink to='/Map' className={
                ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
              }><MapIcon/>  Map</NavLink>
            </>           
          }

          <NavLink to='/About' className={
            ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
          }><AboutIcon />  About</NavLink>
          <NavLink to='/Settings' className={
            ({ isActive }) => `w3-bar-item w3-button w3-padding ${isActive ? 'w3-blue' : ''}` 
          }><i className="fa fa-cog fa-fw"></i>  Settings</NavLink><br /><br />
        </div>
      </nav>
      
      {//Overlay effect when opening sidebar on small screens
      }
      <div className="w3-overlay w3-hide-large w3-animate-opacity" onClick={() => w3_close()} style={{cursor:"pointer"}} title="close side menu" id="myOverlay">
      </div>
    </>
  );
};

export default Sidebar;
