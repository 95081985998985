import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { useStore } from '../services/store';
import { SelectTable } from './Table';



export default function TableDrawer({ columns, listTable, idCol }) {
  const tableOpen = useStore(state => state.tableOpen);
  const setTableOpen = useStore(state => state.setTableOpen);  
  const [loading, setLoading] = useState(false);

  //console.log("Columns:")
  //console.log(columns)
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setTableOpen(open);
  };

  return (
    <Drawer
      anchor={'right'}
      open={tableOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{
        sx: { width: "60%" },
      }}            
        >
      <SelectTable
        columns={columns}
        listTable={listTable}
        loading={loading}        
        setLoading={setLoading}
        idCol={idCol}
      />
    </Drawer>
  );
}