import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { useStore } from '../services/store';
import useCrudActions from '../components/CrudActions';
import CrudButtons from '../components/DesktopCrudButtons';
import { TermFields, PlaceFields, ActorFields } from '../components/VocabFields';
import RecordsTable from '../components/VocabRecords';
import TableDrawer from '../components/TableDrawer';
import Audit from '../components/MimaAudit';
import Orcid from '../components/MimaOrcid';
import { TermIcon } from '../components/LocalIcons';
import {HalfPage} from "../components/Sheets";
import { SelectTable, SearchTable } from '../components/Table';
import { ReadMap, EditMap } from "../components/MimaMap";
import { makeRequest } from '../services/query';

 
const NavLeft = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const changeType = (newType) => {
    // This function changes the category while keeping the id the same.
    navigate(`/Vocabulary/${newType}/${id ? id : ''}`);
  };

  return (
    <div className="w3-center w3-xlarge w3-text-white w3-dark-grey w3-round-xxlarge leftMenu">
      <span className="fa fa-language fa-fw w3-transparent w3-hover-shadow" onClick={() => changeType('Term')} style={{cursor:"pointer"}} />
      <span className="fa fa-map-marker fa-fw w3-transparent w3-hover-shadow" onClick={() => changeType('Place')} style={{cursor:"pointer"}} />
      <span className="fa fa-users fa-fw w3-transparent w3-hover-shadow" onClick={() => changeType('Actor')} style={{cursor:"pointer"}} />
    </div>
    );
};

const NavRight = () => {
  function scrollto(id, instant){
    if(instant && navigator.userAgent.match(/chrome|chromium|crios|edg/i)){
      document.getElementById(id).scrollIntoView();
    } else {
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
    };
  }
  return (
    <div className="w3-center w3-xlarge w3-text-white w3-display-right w3-dark-grey w3-round-xxlarge w3-margin rightMenu">
      <span className="fa fa-list fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('table')} style={{cursor:"pointer"}} />
      <span className="fa fa-link w3-transparent w3-hover-shadow" onClick={() => scrollto('records')} style={{cursor:"pointer"}} />
      <span className="fa fa-map-o fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('map')} style={{cursor:"pointer"}} />
      <span className="fa fa-eye fa-fw w3-transparent w3-hover-shadow" onClick={() => scrollto('audit')} style={{cursor:"pointer"}} />      
    </div>
    );
};

const Terms = () => {
  console.log("Render Terms")

  let { id } = useParams(); // Grabs the entryID from the URL 

  const termTypes = useStore(state => state.termTypes);
  const currData = useStore(state => state.currTerm);
  const setCurrData = useStore(state => state.setCurrTerm);

  const apiTable = 'edit_terms'

  console.log('currData');
  console.log(currData);

  useEffect(() => {
    if (id){
      const loadData = async () => {
        try {
          const getData = await makeRequest('get', `${apiTable}?uuid=eq.${id}`, {}, {});
          setCurrData(getData[0])
        } catch (error) {
          console.error('Could not load data for record:', error);
        }
      };
      loadData();
    }
  }, [id]);

  useCrudActions({
    setSelectedRowData: setCurrData,
    selectedRowData: currData,
    viewTable: apiTable,  
    editTable: apiTable
  });

  const columns = [
    { field: 'term_name', 
      headerName: 'Name', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'term_type', 
      headerName: 'Type', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },    
    { field: 'authority', 
      headerName: 'Authority', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },    
  ]

  return (
    <>
      <HalfPage>
        <Typography variant="h6" >
            Terms
        </Typography>       
        { !currData ?
          <div className="fill-main" id="term">
            <CrudButtons rowSelected={false} />
          </div>        
          :
          <div className="fill-main" id="term">
            <TermFields 
              currData={currData} 
              setCurrData={setCurrData} 
            />
            <Grid container spacing={1} padding >
              <CrudButtons rowSelected={true} />
            </Grid>
          </div>
        }
      </HalfPage>
      <HalfPage>
        <div className="fill-main" id="table">
          <SearchTable 
            listData={termTypes}
            searchCol={'term_name'}
            listCol={'term_type'}
            columns={columns}
            apiTable={apiTable}
          />
        </div>
        <div className="fill-main" id="records">
          <RecordsTable 
            currData={currData} 
            parent={'term'}
          />
        </div>
        <ReadMap 
          currData={currData}
          setCurrData={setCurrData}
          parent={'vocabulary'}
        />          
        <Audit 
          currData={currData}
          setCurrData={setCurrData}
          parent={'vocabulary'}
        />
        { currData &&          
        <NavRight />
        }               
      </HalfPage>            
    </>
    );
};

const Places = () => {
  console.log("Render Places")

  let { id } = useParams(); // Grabs the entryID from the URL  

  const currData = useStore(state => state.currPlace);
  const setCurrData = useStore(state => state.setCurrPlace);
  const [loading, setLoading] = useState(false);
  const [dataForDb, setDataForDb] = useState(null);

  //console.log(currData);

  //Preparing object for writing to database
  useEffect(() => {
    if (currData) {
      const { geojson, ...preppedData } = currData;
      setDataForDb(preppedData);
    }
  }, [currData]); // This effect runs whenever `currData` changes

  const apiTable = 'v_place'
  const editTable = 'edit_place'


  useEffect(() => {
    if (id){
      const loadData = async () => {
        try {
          const getData = await makeRequest('get', `${apiTable}?uuid=eq.${id}`, {}, {});
          setCurrData(getData[0])
        } catch (error) {
          console.error('Could not load data for record:', error);
        }
      };
      loadData();
    }
  }, [id]);

  useCrudActions({
    setSelectedRowData: setCurrData,
    selectedRowData: dataForDb,
    viewTable: apiTable,  
    editTable: editTable
  });

  const columns = [
    { field: 'uuid', 
      headerName: 'UUID', 
      flex: 2, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true,
    },    
    { field: 'name', 
      headerName: 'Name', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'place_type', 
      headerName: 'Type', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },    
    {
      field: 'authorities',
      headerName: 'Authority',
      flex: 1,
      editable: false,
      disableColumnMenu: false,
      sortable: false, // This could be true if you want to enable sorting
      renderCell: (params) => {
        const authorities = params.row.authorities;
        const handleClick = (uri) => {
          window.open(uri, '_blank');
        };
        if(authorities){
          return (
            <>
              {Object.entries(authorities).filter(([_, value]) => value['dc:identifier']).map(([sourceName, { 'dc:identifier': identifier }]) => (
                <Chip
                  key={sourceName}
                  label={sourceName}
                  onClick={() => handleClick(identifier)}
                  sx={{ margin: 0.2 }}
                  size="small"
                  variant="outlined"
                />
              ))}
            </>
          );
        };
        return <span>No data</span>; 

      }
    },   
  ]

  return (
    <>
      <HalfPage>
        <Typography variant="h6" >
            Places
        </Typography>           
        { !currData ?      
          <div className="fill-main" id="place">
            <CrudButtons rowSelected={false} />
          </div>        
          :
          <div className="fill-main" id="place">
            <PlaceFields 
              currData={currData} 
              setCurrData={setCurrData} 
            />
            <Grid container spacing={1} padding >
              <CrudButtons rowSelected={true} />
            </Grid>
          </div>
        }
      </HalfPage>
      <HalfPage>
        <div className="fill-main" id="table">
          <SearchTable 
            searchCol={'name'}
            columns={columns}
            apiTable={apiTable}
          />
        </div>
        <div className="fill-main" id="records">
          <RecordsTable 
            currData={currData} 
            parent={'place'}
          />
        </div>
        <EditMap 
          currData={currData}
          setCurrData={setCurrData}
          parent={'place'}
        />          
        <Audit 
          currData={currData}
          setCurrData={setCurrData}
          parent={'place'}
        />          
        <NavRight />
      </HalfPage>            
    </>
    );
};

const Actors = () => {
  console.log("Render Actors")

  let { id } = useParams(); // Grabs the entryID from the URL  

  const currData = useStore(state => state.currActor);
  const setCurrData = useStore(state => state.setCurrActor);
  const [loading, setLoading] = useState(false);

  const apiTable = 'edit_actor'


  useEffect(() => {
    if (id){
      const loadData = async () => {
        try {
          const getData = await makeRequest('get', `${apiTable}?uuid=eq.${id}`, {}, {});
          setCurrData(getData[0])
        } catch (error) {
          console.error('Could not load data for record:', error);
        }
      };
      loadData();
    }
  }, [id]);

  useCrudActions({
    setSelectedRowData: setCurrData,
    selectedRowData: currData,
    viewTable: apiTable,  
    editTable: apiTable
  });

  const columns = [
    { field: 'uuid', 
      headerName: 'UUID', 
      flex: 2, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true,
    },      
    { field: 'name', 
      headerName: 'Name', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'initials', 
      headerName: 'Initials', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },        
    { field: 'orcid', 
      headerName: 'ORCID ID', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },      
  ]

  return (
    <>
      <HalfPage>
        <Typography variant="h6" >
            Actors
        </Typography>           
        { !currData ?      
          <div className="fill-main" id="actor">
            <CrudButtons rowSelected={false} />
          </div>        
          :
          <div className="fill-main" id="actor">
            <ActorFields currData={currData} setCurrData={setCurrData} />
            <Grid container spacing={1} padding >
              <CrudButtons rowSelected={true} />
            </Grid>
          </div>
        }
      </HalfPage>
      <HalfPage>
        <div className="fill-main" id="table">
          <SelectTable
            columns={columns}
            listTable={apiTable}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        <Orcid 
          orcid={currData?.orcid}
          parent={'actor'}
        />
        <Audit 
          currData={currData}
          setCurrData={setCurrData}
          parent={'actor'}
        />          
        <NavRight />
      </HalfPage>            
    </>
    );
};
const Vocabulary = () => {
  console.log("Render Vocabulary")
  const setTableOpen = useStore(state => state.setTableOpen);
  const vocabType = useStore(state => state.vocabType);
  const setVocabType = useStore(state => state.setVocabType);  
  const [loading, setLoading] = useState(false);  

  const { type } = useParams();
  if(type) {
    setVocabType(type)
  }

  const apiTable = 'list_vocab'
  const listColumns = [
    { field: 'uuid', 
      headerName: 'UUID', 
      flex: 2, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true,
    },    
    { field: 'name', 
      headerName: 'Name', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'parent', 
      headerName: 'Type', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },  
    { field: 'type', 
      headerName: 'Sub-Type', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },         
    { field: 'authorities', 
      headerName: 'Authority', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },    
  ]

  return (
    <>
      <header className="w3-container" >
        <Typography variant="h5" gutterBottom >
          <TermIcon /> Vocabulary
        </Typography>
        <a href="#" className="w3-bar-item w3-button w3-padding w3-right" type="button" onClick={() => setTableOpen(true)}>
          <i className="fa fa-list fa-fw"></i>  View list
        </a>
      </header>
      <div className="row">
      { vocabType === 'Term' ?
        <Terms />
        : vocabType === 'Place' ?
        <Places />
        : vocabType === 'Actor' ?
        <Actors />
        : <Terms />
      }
        <NavLeft />
      </div>
      <TableDrawer 
        columns={listColumns}
        listTable={apiTable}
      />      
    </>
    );
};

export default Vocabulary;
