import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import { renderBooleanCell } from '../helpers/tableRenders';
import { ViewTable } from '../components/Table';
import { 
  goToRecord
} from '../helpers/buttonActions';

const RecordsTable = ({ currData, parent }) => {
  console.log("Render RecordsTable")
  const [listTable, setListTable] = useState('v_all_data');
  const navigate = useNavigate();
  
  
  const columns = [
    {
      field: 'uuid',
      headerName: 'UUID',
      width: 90,
    },
    {
      field: 'Link',
      headerName: '',
      width: 90,
      renderCell: (params) => (
        <IconButton 
          aria-label="Open" 
          onClick={() => goToRecord(navigate, params.row.entry_id)}
        >
          <LaunchIcon />
        </IconButton>
      ),
    },     
    { field: 'entry_id', 
      headerName: 'Id', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'entry_name', 
      headerName: 'Name', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'placename', 
      headerName: 'Place', 
      flex: 1, 
      type: 'text', 
      editable: false, 
      disableColumnMenu: false, 
      sortable: true  
    },
    { field: 'complete', 
      headerName: 'Complete', 
      flex: 1, 
      type: 'boolean', 
      renderCell: renderBooleanCell, 
      width: 50,
      sortable: true
    },
  ]

  const getTableBasedOnType = (type) => {
    if (type?.startsWith('doc_')) {
      return 'v_documentary';
    } else if (type?.startsWith('arch_')) {
      return 'v_archaeological';
    } else if (type?.startsWith('viz_')) {
      return 'v_visual';
    }
    return 'v_all_data';
  };

  const getQueryBasedOnType = (type, uuid) => {
  // Check if the term_type is a regular UUID or UUID[].  
  const arrayTermTypes = ["index_topic", "keyword", "tag"]; 

  if(uuid){
    if(arrayTermTypes.includes(type)) {
      // For UUID arrays, use 'contains' operation rather than 'equals'.
      return `?${type}=cs.{"${uuid}"}`;  
    } else {
      // For regular UUIDs, just append the term_type and uuid to the query string.
      return `?${type}=eq.${uuid}`;
    }    
  } else {
    return '';
  }

  };


  useMemo(() => {
    const uuid = currData?.uuid;
    if (parent === 'term') {
      const termType = currData?.term_type;
      const table = getTableBasedOnType(termType);
      const query = getQueryBasedOnType(termType, uuid);  
      setListTable(`${table}${query}`)
    } else if (parent === 'place') {
      setListTable(`v_all_data?place_uuid=eq.${uuid}`)
    } else if (parent === 'actor') {
      setListTable(`v_all_data?updated_by_uuid=eq.${uuid}`)
      //NB: updated_by_uuid doesn't exist in theis view atm!
    } 
  }, [currData]);



  return (
    <ViewTable
      columns={columns}
      listTable={listTable}
    />
  );
};

export default RecordsTable;
